/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
} from "react";
import PropTypes from "prop-types";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { style } from "style";
import urls from "utils/constants/urls";
import Confetti from "react-confetti";

import { Creators as PublicationActions } from "store/ducks/detailspublication";
import { Creators as PublicationReactionActions } from "store/ducks/reactions";
import { Creators as ModalAlertActions } from "store/ducks/modalAlert";
import { Creators as PostCommentsActions } from "store/ducks/postComments";

import Portal from "templates/Portal";
import Flex from "components/core/Grid/Flex";
import Avatar from "components/core/Avatar/Avatar";
import Button from "components/core/Button";
import Carousel from "components/core/Carousel";
import Quiz from "components/presentation/Quiz";
import Survey from "components/presentation/Survey";
import OpenQuestion from "components/presentation/OpenQuestion";
import Tags from "components/core/Tags";
import { ReactComponent as ArrowRight } from "assets/img/icons/arrow-right.svg";
import { ReactComponent as GoBackIcon } from "assets/img/icons/goBackIcon.svg";
// import { ReactComponent as LikeIcon } from "assets/img/icons/heartlike.svg";
import { ReactComponent as CommentIcon } from "assets/img/icons/iconCommentThin.svg";

import TitlePageComponent from "utils/TitlePageComponent";
import { smartFormatterDate, manageClasses } from "helpers/sharedHelpers";

import { useAnalytics } from "utils/hooks";
import { ReactComponent as IconFlagCheck } from "assets/img/icons/favoriteIconCheck.svg";
import { ReactComponent as IconFlag } from "assets/img/icons/favoriteIcon.svg";
import { ReactComponent as LikeIconDefault } from "assets/img/icons/iconLikeDefault.svg";
import { ReactComponent as LikeIcon } from "assets/img/icons/iconLike.svg";
import { ReactComponent as LovedIcon } from "assets/img/icons/iconLoved.svg";
import { ReactComponent as LaughterIcon } from "assets/img/icons/iconLaughter.svg";
import { ReactComponent as SurprisedIcon } from "assets/img/icons/iconSurprised.svg";
import { ReactComponent as SupportIcon } from "assets/img/icons/iconSupport.svg";
import { ReactComponent as CongratsIcon } from "assets/img/icons/iconCongrats.svg";
import ReactPlayer from "react-player";
import { ModalProvider } from "styled-react-modal";
import SidebarPublication from "./sidebarPublication";
import SkeletonPublication from "./PublicationSkeleton";
import {
  PublicationContainer,
  PublicationContent,
  GoBackButton,
  Card,
  CoverImage,
  Video,
  Image,
  Header,
  PubDate,
  PubTitleContainer,
  PubTitle,
  FavoritePost,
  Author,
  TextContainer,
  Text,
  Link,
  FormSection,
  FormTitle,
  ButtonsContainer,
  CommentsContainer,
  HeaderComments,
  TitleCommentsContainer,
  AuthorCommentsContainer,
  TitleComments,
  AuthorComments,
  CoverComments,
  ContainerConfetti,
  ModalCardCongratulation,
  TextCard,
  ContainerCard,
} from "./PublicationStyle";

import Comments from "./comments/Comments";
import * as S from "./sidebarPublication/SidebarPublicationStyle";
import { reactionsList } from "../../../utils/Reactions/reactions";
import { Reactions } from "../../../components/core/Reactions/Reactions";
import ReactionsViewModal from "./reactionsModal/ReactionsViewModal";

useAnalytics("screen_publication_detail");

const Publication = () => {
  const { id, title } = useParams();
  const dispatch = useDispatch();
  const intl = useIntl();
  const [coverId, setCoverId] = useState(null);
  const [quizzes, setQuizzes] = useState([]);
  const [surveys, setSurveys] = useState([]);
  const [idMentionedComment, setIdMentionedComment] = useState(null);
  const [showAnimation, setShowAnimation] = useState(null);
  const [openQuestions, setOpenQuestions] = useState([]);
  const history = useHistory();
  const goBack = () => history.goBack();
  const [isDesktop, setDesktop] = useState(window.innerWidth > 1024);
  const date = useState(new Date());
  const changeDataPosition = () => {
    setDesktop(window.innerWidth > 1024);
  };
  const [showComments, setShowComments] = useState(false);
  const [showReactions, setShowReactions] = useState(false);
  const [openReactionsModal, setOpenReactionsModal] = useState(false);
  const [typeOfReaction, setTypeOfReaction] = useState("");
  const [shouldSaveAllReactions, setShouldSaveAllReactions] = useState(true);
  const [selectedId, setSelectedId] = useState();
  // const [scrollParentRef, setScrollParentRef] = useState();
  const commentRef = useRef();
  const touchTimeOutRef = useRef();
  const reactionRef = useRef();

  const reactionsIconsList = {
    like: { icon: LikeIcon, text: "Curtir" },
    loved_it: { icon: LovedIcon, text: "Amei" },
    laughter: { icon: LaughterIcon, text: "Risada" },
    surprized: { icon: SurprisedIcon, text: "Surpreso" },
    support: { icon: SupportIcon, text: "Apoio" },
    congrats: { icon: CongratsIcon, text: "Parabéns" },
  };

  const reactionDefault = {
    icon: LikeIconDefault,
    title: reactionsIconsList.like.text,
    value: reactionsList.like.value,
  };

  useEffect(() => {
    window.addEventListener("resize", changeDataPosition);
    return () => window.removeEventListener("resize", changeDataPosition);
  }, []);

  useEffect(() => {
    title && setIdMentionedComment(Number(title.split(":").pop()));
  }, [title]);

  const idUser = localStorage.getItem("idUser");
  const { videoAutoplay } =
    JSON.parse(localStorage.getItem(`localConfig-${idUser}`)) || {};

  const isSuvinil = style.names.nameApp === "Pintou Parceria Suvinil";

  const {
    isLoading,
    sendingForm,
    formSent,
    error,
    publication: {
      likes: totalPublicationReactions,
      canvas_contents: canvasContents,
      published_at: publishedAt,
      kind: postKind,
      title: publicationTitle,
      favorite,
      user,
      userslikes,
      tags,
      user_reaction,
      // liked,
      interaction_message,
      special,
      end_date: endDate,
      likes,
      comments_amount,
      reactions,
      comments_enabled,
      likes_enabled,
      min_size_comments,
    },
    publication,
    markUsersList,
    sendingComment,
  } = useSelector(({ detailspublication }) => detailspublication);
  const { user: currentUser } = useSelector(({ user }) => user);

  const {
    reactions: postReactions,
    allReactions,
    commentReactions,
    liked,
    isLoading: isReactionsLoading,
  } = useSelector(({ reactions }) => reactions);

  const { comments: canvas_comments, isLoading: isLoadingComment } =
    useSelector(({ postComments }) => postComments);

  const currentUserHasReacted =
    user_reaction && user_reaction.user_id === currentUser.id;

  const dispatchGetPostComments = useCallback(() => {
    dispatch(
      PostCommentsActions.getPostComments({
        postId: id,
        page: 1,
        commentId: idMentionedComment,
      }),
    );
  }, [dispatch, id, idMentionedComment]);

  useEffect(() => {
    !isLoadingComment && dispatchGetPostComments();
  }, [dispatchGetPostComments]);

  const isForm = ({ kind }) =>
    kind === "survey" ||
    kind === "quiz" ||
    kind === "open_question" ||
    kind === "form";

  const containsForm = ({ canvas_contents }) => {
    let containsForm = canvas_contents.find((content) => isForm(content));
    if (containsForm !== undefined) return true;
    return false;
  };

  const hasForm = () => canvasContents.findIndex(isForm) !== -1;
  const hasNoAnswer = (form) => {
    switch (form.kind) {
      case "quiz": {
        const verifyQuizAnswer = (selectedQuestion) =>
          quizzes[selectedQuestion].surveys_ids.length === 0;
        const selectedQuiz = quizzes.findIndex(
          (quiz) => quiz.canvas_content_id === form.id,
        );
        return selectedQuiz === -1 ? true : verifyQuizAnswer(selectedQuiz);
      }
      case "survey": {
        const verifySurveyAnswer = (selectedQuestion) =>
          surveys[selectedQuestion].survey_id === null;
        const selectedSurvey = surveys.findIndex(
          (survey) => survey.canvas_content_id === form.id,
        );
        return selectedSurvey === -1
          ? true
          : verifySurveyAnswer(selectedSurvey);
      }
      case "open_question": {
        const verifyQuestionAnswer = (selectedQuestion) =>
          openQuestions[selectedQuestion].response.length === 0;
        const selectedQuestion = openQuestions.findIndex(
          (question) => question.canvas_content_id === form.id,
        );
        return selectedQuestion === -1
          ? true
          : verifyQuestionAnswer(selectedQuestion);
      }
      default:
        return null;
    }
  };
  const disabledForm = canvasContents
    ?.map((section) => isForm(section) && hasNoAnswer(section))
    .includes(true);

  const formExpired = useMemo(
    () => Date.parse(endDate) < Date.parse(date[0]),
    [endDate, date],
  );

  const dispatchGetDetailsPublication = useCallback(
    () => dispatch(PublicationActions.getDetailsPublication(id)),
    [dispatch, id],
  );

  useEffect(() => {
    dispatchGetDetailsPublication();
  }, [dispatchGetDetailsPublication]);

  useEffect(() => {
    if (special && showAnimation === false && liked) {
      setShowAnimation(liked);
    }
  }, [liked, special, showAnimation]);

  useEffect(() => {
    const message = {
      type: formSent ? "success" : "errors",
      title: formSent
        ? intl.messages["modal.textPage.successModal"]
        : intl.messages["modal.textPage.somethingWentWrong"],
      msg: formSent ? intl.messages["modal.textPage.formSent"] : error,
      buttons: [
        {
          title: intl.messages["general.buttons.ok"],
          event: () => {
            dispatchGetDetailsPublication();
            setQuizzes([]);
            setSurveys([]);
            setOpenQuestions([]);
          },
        },
      ],
    };
    formSent !== null && dispatch(ModalAlertActions.setAlertsRequest(message));
    // eslint-disable-next-line
  }, [formSent]);

  const mountMessageLimitDate = () => {
    return {
      type: "Info",
      title: "Ops, fica para a próxima!",
      msg: "A data limite para respostas foi atingida. Fique ligado nas próximas postagens no seu mural.",
      buttons: [
        {
          title: intl.messages["general.buttons.ok"],
          event: () => {
            dispatchGetDetailsPublication();
          },
        },
      ],
    };
  };

  useEffect(
    () => setCoverId(coverSection()?.id),
    // eslint-disable-next-line
    [canvasContents],
  );

  const showCommentSection = () => {
    setShowComments(true);
    const elmnt = document.getElementById("commentSectionId");
    elmnt.scrollTop = 0;
  };

  const coverSection = () =>
    // TODO: Align cover logic both in the feed and in the post itself, which is currently different. Better view also in admin.

    // OLD LOGIC:
    // canvasContents?.find(
    //   ({ kind }) => kind === "image" || kind === "carousel" || kind === "video",
    // ) || null;

    // ACTUAL LOGIC:

    canvasContents !== undefined &&
    (canvasContents[0]?.kind === "image" ||
      canvasContents[0]?.kind === "carousel" ||
      canvasContents[0]?.kind === "video" ||
      canvasContents[0]?.kind === "embeded_video_link")
      ? canvasContents[0]
      : null;

  const renderPostCover = (section) => {
    switch (section?.kind) {
      case "image":
        return (
          <CoverImage
            src={section?.canvas_content_images[0]?.image?.url}
            showComments={showComments}
          />
        );
      case "embeded_video_link":
        return (
          <div
            style={{
              width: "100%",
              borderRadius: "12px 12px 0 0",
              overflow: "hidden",
            }}
          >
            <ReactPlayer url={section.link} width="100%" controls />
          </div>
        );
      case "carousel":
        return (
          <Carousel
            itensRender={section?.canvas_content_images.map(({ id, image }) => (
              <CoverImage key={id} src={image.url} />
            ))}
          />
        );
      case "video":
        return (
          <Video
            key={section.id}
            width="100%"
            autoPlay={isSuvinil ? false : videoAutoplay || false}
            height="340"
            preload="none"
            loop
            muted
            controls
            controlsList="nodownload"
            showComments={showComments}
            poster={
              section?.canvas_content_videos[0]?.video?.preview_image?.url
            }
          >
            <source
              src={section.canvas_content_videos[0].video.url}
              type="video/mp4"
            />
          </Video>
        );
      default:
        return null;
    }
  };

  let questionsCounter = 0;
  const renderQuestionCounter = () => {
    questionsCounter += 1;
    return questionsCounter;
  };

  const handleQuiz = (updatedQuiz) => {
    const selectedQuiz = quizzes.findIndex(
      (quiz) => quiz.canvas_content_id === updatedQuiz.canvas_content_id,
    );

    if (selectedQuiz === -1) {
      setQuizzes([...quizzes, updatedQuiz]);
    } else {
      setQuizzes(
        quizzes.map((quiz, index) =>
          index === selectedQuiz ? updatedQuiz : quiz,
        ),
      );
    }
  };

  const handleSurvey = (updatedSurvey) => {
    const selectedSurvey = surveys.findIndex(
      (survey) => survey.canvas_content_id === updatedSurvey.canvas_content_id,
    );

    if (selectedSurvey === -1) {
      setSurveys([...surveys, updatedSurvey]);
    } else {
      setSurveys(
        surveys.map((survey, index) =>
          index === selectedSurvey ? updatedSurvey : survey,
        ),
      );
    }
  };

  const handleOpenQuestion = (updatedQuestion) => {
    const selectedQuestion = openQuestions.findIndex(
      (question) =>
        question.canvas_content_id === updatedQuestion.canvas_content_id,
    );

    if (selectedQuestion === -1) {
      setOpenQuestions([...openQuestions, updatedQuestion]);
    } else {
      setOpenQuestions(
        openQuestions.map((question, index) =>
          index === selectedQuestion ? updatedQuestion : question,
        ),
      );
    }
  };

  const handleFavorite = (id, favorite) => {
    favorite
      ? dispatch(
          PublicationActions.deleteFavoritePublicationPageRequest({
            id,
          }),
        )
      : dispatch(
          PublicationActions.postFavoritePublicationPageRequest({
            id,
          }),
        );
  };

  const sendFormAnswer = () => {
    const formAnswer = {
      publicationId: id,
      body: {
        quizzes_responses: quizzes,
        surveys_responses: surveys,
        open_responses: openQuestions,
      },
    };
    return dispatch(PublicationActions.postFormAnswer(formAnswer));
  };

  const sendSurveyAnswer = () => {
    const surveyAnswer = {
      canvas_publication_id: id,
      canvas_content_id: canvasContents.find(
        (canva) => canva.kind === "survey" || canva.kind === "quiz",
      )?.id,
      body: {
        surveys_ids: surveys.length
          ? surveys.map((survey) => survey.survey_id)
          : quizzes[0]?.surveys_ids,
      },
    };

    return dispatch(PublicationActions.postSurveyAnswer(surveyAnswer));
  };

  const onHold = () => {
    setShowReactions(true);
    touchTimeOutRef.current = [touchTimeOutRef.current[0], true];
  };

  const handleClick = (e) => {
    if (!reactionRef?.current?.contains(e.target)) {
      setShowReactions(false);
    }
  };

  const renderPostSection = ({
    id: contentId,
    kind,
    title,
    link,
    text,
    canvas_content_images: canvasContentImages,
    canvas_content_videos: canvasContentVideos,
    content_survey: contentSurvey,
    content_open_question,
    votes_amount: allVotesAmount,
    end_date,
  }) => {
    switch (kind) {
      case "image":
        return (
          contentId !== coverId && (
            <Flex key={contentId} flexDirection="column" mb={5}>
              {canvasContentImages.map(({ id: imageId, image }) => (
                <Image key={imageId} src={image.url} />
              ))}
            </Flex>
          )
        );
      case "text":
        return (
          <TextContainer key={contentId} flexDirection="column" mb={5}>
            <pre>
              <Text dangerouslySetInnerHTML={{ __html: text }} />
            </pre>
          </TextContainer>
        );
      case "link":
        return (
          <Link
            key={contentId}
            href={`${link}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <p>{title}</p>
            <ArrowRight />
          </Link>
        );
      case "carousel":
        return (
          contentId !== coverId && (
            <Flex key={contentId} flexDirection="column" mb={5}>
              <Carousel
                itensRender={canvasContentImages.map(({ id, image }) => (
                  <Image key={id} src={image.url} />
                ))}
              />
            </Flex>
          )
        );
      case "video":
        return (
          contentId !== coverId && (
            <Flex key={contentId} flexDirection="column" mb={5}>
              {canvasContentVideos.map(({ id: videoId, video }) => (
                <Video
                  key={videoId}
                  width="100%"
                  autoPlay={isSuvinil ? false : videoAutoplay || false}
                  height="340"
                  preload="none"
                  loop
                  muted
                  controls
                  controlsList="nodownload"
                  poster={video?.preview_image?.url}
                >
                  <source src={video.url} type="video/mp4" />
                </Video>
              ))}
            </Flex>
          )
        );
      case "embeded_video_link":
        return (
          contentId !== canvasContents[0].id && (
            <div
              style={{
                width: "100%",
                borderRadius: "8px 8px 8px 8px",
                overflow: "hidden",
              }}
            >
              <ReactPlayer
                url={link}
                width="100%"
                controls
                style={{ marginBottom: "20px" }}
              />
            </div>
          )
        );
      case "quiz":
        return (
          <FormSection
            key={contentId}
            flexDirection="column"
            mb={5}
            spaceChildren={3}
          >
            <FormTitle>
              {renderQuestionCounter()}. {text}
            </FormTitle>
            <Quiz
              contentId={contentId}
              options={contentSurvey}
              handleQuiz={handleQuiz}
              allVotesAmount={allVotesAmount}
              endDate={end_date}
            />
          </FormSection>
        );
      case "survey":
        return (
          <FormSection
            key={contentId}
            flexDirection="column"
            mb={5}
            spaceChildren={3}
          >
            <FormTitle>
              {renderQuestionCounter()}. {text}
            </FormTitle>
            <Survey
              contentId={contentId}
              options={contentSurvey}
              handleSurvey={handleSurvey}
              allVotesAmount={allVotesAmount}
              endDate={end_date}
            />
          </FormSection>
        );
      case "open_question":
        return (
          <FormSection
            key={contentId}
            flexDirection="column"
            mb={5}
            spaceChildren={3}
          >
            <FormTitle>
              {renderQuestionCounter()}. {content_open_question.question}
            </FormTitle>
            <OpenQuestion
              contentId={contentId}
              question={content_open_question}
              handleOpenQuestion={handleOpenQuestion}
              endDate={endDate}
              showLimitDateAlert={() =>
                dispatch(
                  ModalAlertActions.setAlertsRequest(mountMessageLimitDate()),
                )
              }
            />
          </FormSection>
        );
      default:
        return null;
    }
  };

  renderPostSection.propTypes = {
    id: PropTypes.number.isRequired,
    kind: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    canvas_content_images: PropTypes.array.isRequired,
    canvas_content_videos: PropTypes.array.isRequired,
    content_survey: PropTypes.array.isRequired,
    votes_amount: PropTypes.number,
    end_date: PropTypes.date,
  };

  const { publications, isLoading: isLoadingPublication } = useSelector(
    ({ mural }) => mural,
  );

  const { isLoading: isLoadingDetailsPublication } = useSelector(
    ({ detailspublication }) => detailspublication,
  );

  const { isLoading: isLoadingUser } = useSelector(({ user }) => user);

  const handlePublicationShowReactions = (payload) => {
    const { id, reaction } = payload;

    dispatch(
      PublicationReactionActions.getPublicationAllReactions({
        id,
        reaction,
        page: 1,
        per_page: totalPublicationReactions,
      }),
    );

    dispatch(
      PublicationReactionActions.getPublicationReactions({
        id,
        reaction,
        page: 1,
        per_page: 5,
      }),
    );

    setOpenReactionsModal(true);
    setTypeOfReaction("post");
    setShouldSaveAllReactions(true);
    setSelectedId(id);
  };

  return (
    <Portal
      // setRef={setScrollParentRef}
      sidebarContext={
        <SidebarPublication
          isLiked={(liked) => !liked && setShowAnimation(liked)}
          idMentionedComment={idMentionedComment}
          publications={publications}
          isLoadingPublication={isLoadingPublication}
          isLoading={isLoadingDetailsPublication}
          isLoadingUser={isLoadingUser}
          liked={liked}
          likes={likes}
          userslikes={userslikes}
          comments_enabled={comments_enabled}
          likes_enabled={likes_enabled}
          min_size_comments={min_size_comments}
          sendingComment={sendingComment}
          // markUsersPublish={markUsersList}
          // scrollParentRef={scrollParentRef}
        />
      }
      fullHeight
    >
      {isLoading ? (
        <SkeletonPublication />
      ) : (
        <Flex justifyContent="center">
          <PublicationContainer
            id="commentSectionId"
            ref={commentRef}
            showComments={showComments}
            onClick={handleClick}
          >
            {!showComments && (
              <>
                <ContainerConfetti showContainer={showAnimation}>
                  <ContainerCard>
                    <ModalCardCongratulation showCardModal={showAnimation}>
                      <TextCard showTextCard={showAnimation}>
                        {interaction_message || "Você interagiu e ganhou "}
                      </TextCard>
                    </ModalCardCongratulation>
                  </ContainerCard>
                  <Confetti
                    recycle={false}
                    run={showAnimation}
                    numberOfPieces={500}
                  />
                </ContainerConfetti>
                <TitlePageComponent title={publicationTitle} />
                <PublicationContent>
                  <GoBackButton onClick={goBack}>
                    <GoBackIcon />
                  </GoBackButton>
                  <Card>
                    {renderPostCover(coverSection())}
                    <Header>
                      <PubTitleContainer>
                        <PubTitle>{publicationTitle}</PubTitle>
                        <FavoritePost
                          onClick={() => handleFavorite(id, favorite)}
                        >
                          {favorite ? <IconFlagCheck /> : <IconFlag />}
                        </FavoritePost>
                      </PubTitleContainer>
                      <Flex mt={4} spaceChildren={2} alignItems="center">
                        <Avatar
                          url={user?.image?.url}
                          onClick={() =>
                            history.push(`${urls.ROUTES.PROFILE}/${user?.id}`)
                          }
                        />
                        <Author>{user?.name}</Author>
                      </Flex>
                      {isForm(publication) ? (
                        <PubDate>
                          {smartFormatterDate(publishedAt, "DD/MM/YYYY")} -
                          Respostas até{" "}
                          {smartFormatterDate(endDate, "DD/MM/YYYY")}
                        </PubDate>
                      ) : containsForm(publication) ? (
                        <PubDate>
                          {smartFormatterDate(publishedAt, "DD/MM/YYYY")} -
                          Respostas até{" "}
                          {smartFormatterDate(
                            canvasContents[0].end_date,
                            "DD/MM/YYYY",
                          )}
                        </PubDate>
                      ) : (
                        <PubDate>
                          {smartFormatterDate(publishedAt, "DD/MM/YYYY")}
                        </PubDate>
                      )}
                    </Header>
                  </Card>

                  {canvasContents?.map((section) => renderPostSection(section))}

                  {hasForm() && (
                    <Flex justifyContent="flex-end" mb={5}>
                      <Button
                        name={intl.messages["general.buttons.send"]}
                        action={
                          postKind === "publication"
                            ? sendSurveyAnswer
                            : sendFormAnswer
                        }
                        isLoading={sendingForm}
                        variant={manageClasses({
                          disabled: disabledForm || formExpired,
                        })}
                      />
                    </Flex>
                  )}

                  {!isDesktop && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "40px",
                      }}
                    >
                      {likes > 0 && (
                        <div
                          style={{ display: "flex", alignItems: "center" }}
                          onClick={() =>
                            handlePublicationShowReactions({ id, reaction: "" })
                          }
                        >
                          {likes_enabled && (
                            <>
                              <S.UsersReactions>
                                {Object.keys(reactions)
                                  .sort((a, b) => reactions[b] - reactions[a])
                                  .slice(0, 3)
                                  .map((reaction, index, array) => (
                                    <Avatar
                                      url={reactionsList[reaction].icon}
                                      size="28px"
                                      style={{
                                        zIndex: array.length - index,
                                      }}
                                    />
                                  ))}
                              </S.UsersReactions>
                              <S.Counter>
                                {`${likes} ${likes > 1 ? "reações" : "reação"}`}{" "}
                              </S.Counter>
                            </>
                          )}
                          {comments_amount > 0 && comments_enabled && (
                            <S.Counter style={{ marginLeft: "3px" }}>
                              {" "}
                              {`${
                                comments_enabled && likes_enabled ? "|" : ""
                              } ${comments_amount} ${
                                comments_amount > 1
                                  ? "comentários"
                                  : "comentário"
                              }`}
                            </S.Counter>
                          )}
                        </div>
                      )}
                    </div>
                  )}

                  {!isDesktop && !showComments && (
                    <>
                      <ButtonsContainer
                        ref={reactionRef}
                        likes_enabled={likes_enabled}
                        comments_enabled={comments_enabled}
                      >
                        {likes_enabled && (
                          <>
                            <Reactions
                              type="publicationMobile"
                              idPublication={id}
                              // reactions
                              liked
                              currentUser={currentUser}
                              currentUserHasReacted={currentUserHasReacted}
                              user_reaction={user_reaction}
                              ref={reactionRef}
                              onEmojiClick={() => {
                                setShowReactions(false);
                              }}
                            />
                            <S.LikeContainer liked={liked} ref={reactionRef}>
                              <S.LikeButton
                                ref={reactionRef}
                                isMobile={!isDesktop}
                                disableHover={!isDesktop}
                                show={showReactions}
                                onMouseUp={() => {
                                  clearTimeout(touchTimeOutRef.current[0]);
                                  if (!touchTimeOutRef.current[1]) {
                                    setShowReactions(false);
                                    dispatch(
                                      PublicationReactionActions.postLikedPublication(
                                        {
                                          idPublication: id,
                                          reaction: "like",
                                          isLikeButton: true,
                                          currentUser,
                                          currentUserHasReacted,
                                          user_reaction,
                                        },
                                      ),
                                    );
                                  }
                                }}
                                onMouseDown={() => {
                                  touchTimeOutRef.current = [
                                    setTimeout(() => onHold(), 300),
                                    false,
                                  ];
                                }}
                                style={{ background: "none" }}
                                icon={
                                  currentUserHasReacted
                                    ? reactionsIconsList[
                                        user_reaction?.reaction
                                      ]?.icon
                                    : reactionDefault.icon
                                }
                                name={
                                  currentUserHasReacted
                                    ? reactionsIconsList[
                                        user_reaction?.reaction
                                      ]?.text
                                    : reactionDefault.title
                                }
                                // variant={["gradient"]}
                              />
                            </S.LikeContainer>
                          </>
                        )}
                        {likes_enabled && comments_enabled && (
                          <S.VerticalDivider />
                        )}
                        {!style.variables.child_profiles.length > 0 &&
                          comments_enabled && (
                            <S.CommentButton
                              isMobile={!isDesktop}
                              disableHover={!isDesktop}
                              style={{ background: "none" }}
                              icon={CommentIcon}
                              name="Comentar"
                              variant={["gradient"]}
                              action={() => {
                                showCommentSection();
                              }}
                            />
                          )}
                      </ButtonsContainer>
                    </>
                  )}
                  {/* Attention: Hidden tags */}
                  {/* {!showComments && <Tags tags={tags} />} */}
                </PublicationContent>
              </>
            )}
            {showComments && (
              <CommentsContainer showComments={showComments}>
                <HeaderComments>
                  <GoBackButton onClick={() => setShowComments(false)}>
                    <GoBackIcon />
                  </GoBackButton>
                  <TitleCommentsContainer>
                    <TitleComments>{publicationTitle}</TitleComments>
                    <AuthorCommentsContainer>
                      <Avatar url={user?.image?.url} size="28px" />
                      <AuthorComments>{user.name}</AuthorComments>
                    </AuthorCommentsContainer>
                  </TitleCommentsContainer>
                  <CoverComments>
                    {renderPostCover(coverSection())}
                  </CoverComments>
                </HeaderComments>
                <Comments
                  comments_amount={comments_amount}
                  canvas_comments={canvas_comments}
                  markUsersPublish={markUsersList}
                  sendingComment={sendingComment}
                  disableHover={!isDesktop}
                  isDesktop={isDesktop}
                />
              </CommentsContainer>
            )}

            {openReactionsModal && (
              <ModalProvider>
                <ReactionsViewModal
                  openReactionsModal={openReactionsModal}
                  shouldSaveAllReactions={shouldSaveAllReactions}
                  setOpenReactionsModal={setOpenReactionsModal}
                  setShouldSaveAllReactions={setShouldSaveAllReactions}
                  setTypeOfReaction={setTypeOfReaction}
                  typeOfReaction={typeOfReaction}
                  isReactionsLoading={isReactionsLoading}
                  reactions={postReactions}
                  allReactions={allReactions}
                  isDesktop={isDesktop}
                  id={selectedId}
                />
              </ModalProvider>
            )}
          </PublicationContainer>
        </Flex>
      )}
    </Portal>
  );
};

export default Publication;
