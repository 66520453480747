/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { style } from "style";
import { Creators as AcademyActions } from "store/ducks/academy";

import Portal from "templates/Portal";
import Flex from "components/core/Grid/Flex";
import Button from "components/core/Button";
import { history, manageClasses } from "helpers/sharedHelpers";
import urls from "utils/constants/urls";
import ContentModule from "./ContentModule";
import ContentModuleSkeleton from "./ContentModule/ContentModuleSkeleton";
import CourseProgress from "../../Sidebar/CourseProgress";

import { ContainerModule, QuizzLength, SidebarModule } from "./ModuleStyle";
import { useVideoContext } from "../../../../context/VideoContexts";

const Module = () => {
  const { id } = useParams();
  const pageInCourse = useLocation();
  const { pageTitle } = pageInCourse.state || "";
  const { videoIsFinished, setVideoIsFinished, isVideo } = useVideoContext();
  const dispatch = useDispatch();
  const {
    course_details: courseDetails,
    doneSubmodules,
    isLoading,
    isLoadingModule,
    isLoadingFinalize,
  } = useSelector(({ academy }) => academy);

  const {
    title: titleCourse,
    user_status: userStatus,
    module_courses: courseModules,
  } = courseDetails;

  // pages controllers
  const [contModule, setContModule] = useState(0);
  const [contSubMod, setContSubMod] = useState(0);

  // quiz controllers
  const [contQuiz, setContQuiz] = useState(1);
  const [hasAnswer, setHasAnswer] = useState(null);
  const [isCorrect, setIsCorrect] = useState(null);
  const [idContent, setIdContent] = useState(null);
  const [idAnswer, setIdAnswer] = useState(null);

  // submodules and modules finalized
  const [idCourse, setIdCourse] = useState(null);
  const [idModule, setIdModule] = useState(null);
  const [idSubmodule, setIdSubmodule] = useState(null);
  const [finalizeSubmodule, setFinalizeSubmodule] = useState(null);
  const [finalizedModule, setFinalizedModule] = useState(null);
  const [titleModule, setTitleModule] = useState(null);

  // progress page controllers if quiz
  const [isQuiz, setIsQuiz] = useState(false);
  const quizzes =
    !!courseModules &&
    courseDetails?.module_courses[contModule]?.submodules.map(({ contents }) =>
      contents.find(({ kind_content }) => kind_content === "quiz"),
    );

  const [isFinalized, setIsFinalized] = useState(false);
  const [pageProgress, setPageProgress] = useState(true);

  useEffect(() => {
    dispatch(AcademyActions.getDetailsCourse(id));
  }, [dispatch, id]);

  // function to select item in module
  const moduleItem = useCallback(
    (type) => {
      switch (type) {
        case "module":
          return courseModules[contModule];
        case "submodules":
          return courseModules[contModule]?.submodules;
        case "submodule":
          return courseModules[contModule]?.submodules[contSubMod];
        case "contents":
          return courseModules[contModule]?.submodules[contSubMod]?.contents;
        default:
          return courseModules[contModule]?.submodules[contSubMod]?.contents[0];
      }
    },
    [courseModules, contModule, contSubMod],
  );
  // end

  // function last module and submodule
  const lastSubModule = useCallback(
    (module, submodule) => {
      const isLast =
        courseModules[courseModules.length - 1].submodules[
          courseModules[courseModules.length - 1].submodules.length - 1
        ];
      if (courseModules[module]?.submodules[submodule] === isLast) {
        setIsFinalized(true);
      }
    },
    [courseModules],
  );
  // end

  // finalize modules and submodules
  useEffect(() => {
    if (finalizedModule) {
      dispatch(
        AcademyActions.finalizeModule({
          idCourse,
          idModule,
          titleModule,
          isFinalized,
          titleCourse,
        }),
      );
      setFinalizedModule(false);
    }
  }, [
    finalizedModule,
    idCourse,
    idModule,
    titleModule,
    isFinalized,
    titleCourse,
    dispatch,
  ]);

  const finalizeModule = () => {
    if (isFinalized) {
      setFinalizeSubmodule(true);
      setIdCourse(id);
      setIdSubmodule(moduleItem("submodule").id);
    }
    if (!moduleItem("module").was_finished) {
      setIdCourse(id);
      setIdModule(moduleItem("module").id);
      setTitleModule(moduleItem("module").name);
      setFinalizedModule(true);
    }
  };

  useEffect(() => {
    if (finalizeSubmodule) {
      dispatch(AcademyActions.finalizeSubmodule({ idCourse, idSubmodule }));
      setFinalizeSubmodule(false);
    }
  }, [finalizeSubmodule, idCourse, idSubmodule, titleModule, dispatch]);
  // end

  // page in progress
  useEffect(() => {
    if (!isLoading && pageProgress) {
      if (
        courseModules.length === 1 &&
        courseModules[0].submodules.length === 1
      ) {
        setIsFinalized(true);
      }

      if (pageInCourse.state?.module) {
        setContModule(pageInCourse.state.module);
        setContSubMod(pageInCourse.state.submodule);
      } else if (userStatus?.status === "initiated") {
        const module = courseModules.filter(({ was_finished }) => was_finished);
        const submod = courseModules[module.length]?.submodules.filter(
          ({ was_seen }) => was_seen,
        );

        lastSubModule(module?.length, submod?.length);
        setContModule(module?.length);
        setContSubMod(submod?.length);

        if (moduleItem()?.kind_content === "quiz") {
          const quiz = quizzes.find(
            (item) =>
              courseModules[module.length]?.submodules[submod.length]
                .contents[0] === item,
          );
          if (submod.length > 0) setContQuiz(quiz?.question_number);
          else setContQuiz(1);
        }
      }
    }
  }, [
    courseModules,
    isLoading,
    pageProgress,
    pageInCourse.state,
    lastSubModule,
    userStatus?.status,
  ]);

  // end

  // Back and next function
  const backSlide = () => {
    const hasBack = (submodule) => !!submodule[contSubMod - 1];
    setIsFinalized(null);

    if (hasBack(moduleItem("submodules"))) {
      if (
        courseModules[contModule]?.submodules[contSubMod - 1]?.contents[0]
          .kind_content === "quiz"
      ) {
        setContQuiz(
          courseModules[contModule]?.submodules[contSubMod - 1]?.contents[0]
            .question_number,
        );
      }
      setContSubMod(contSubMod - 1);
    }

    if (contSubMod === 0)
      history.push(`${urls.ROUTES.ACADEMY}/${id}`, { pageTitle });
  };

  const nextSlide = () => {
    setVideoIsFinished(false);
    const hasNext = (modules) => !!modules[contModule + 1];
    const lastSubmod = (submodule) => submodule?.length - 1 === contSubMod;
    const finalizedSubmod =
      moduleItem("submodule")?.was_seen ||
      !!doneSubmodules?.find(
        (doneSubmodule) => doneSubmodule === moduleItem("submodule").id,
      );

    setPageProgress(false);
    setHasAnswer(null);
    setIsCorrect(null);

    if (!finalizedSubmod) {
      setFinalizeSubmodule(true);
      setIdCourse(id);
      setIdSubmodule(moduleItem("submodule")?.id);
    }
    lastSubModule(contModule, contSubMod + 1);

    if (lastSubmod(moduleItem("submodules"))) {
      if (hasNext(courseModules)) {
        setContSubMod(0);
        setContQuiz(1);
        finalizeModule();
        setContModule(contModule + 1);
      }
    } else {
      if (
        courseModules[contModule]?.submodules[contSubMod + 1]?.contents[0]
          .kind_content === "quiz"
      ) {
        setContQuiz(
          courseModules[contModule]?.submodules[contSubMod + 1]?.contents[0]
            .question_number,
        );
      }
      setContSubMod(contSubMod + 1);
    }
  };
  // end

  // Quiz controler function
  const verifyAnswer = (idValue) => {
    const correctAnswer = moduleItem().answers.find(
      (answer) => answer.is_correct,
    );
    lastSubModule(contModule, contSubMod);
    if (correctAnswer) {
      setIsCorrect(idValue === correctAnswer.id);
    }
    setIdCourse(id);
    setIdAnswer(idValue);
    setIdModule(moduleItem("module").id);
    setIdContent(moduleItem().id);
  };

  useEffect(() => {
    if (hasAnswer) {
      dispatch(
        AcademyActions.postAnswer({ idCourse, idModule, idContent, idAnswer }),
      );
    }
  }, [hasAnswer, dispatch, idCourse, idModule, idContent, idAnswer]);
  // end

  // page controller of course progress
  useEffect(() => {
    if (isQuiz) {
      const quiz = quizzes.find((item) => moduleItem() === item);
      setContQuiz(quiz?.question_number);
      setIsQuiz(false);
    }
  }, [isQuiz, quizzes, moduleItem]);

  const selectedPage = (modulePage, submodPage) => {
    setIsFinalized(null);
    setContModule(modulePage);
    setContSubMod(submodPage);
    setIsQuiz(moduleItem().kind_content === "quiz");
  };

  // end
  return (
    <Portal>
      <Flex>
        <SidebarModule>
          <CourseProgress
            pageView={(modulePage, submodPage) =>
              selectedPage(modulePage, submodPage)
            }
          />
        </SidebarModule>
        {isLoading ? (
          <ContainerModule>
            <ContentModuleSkeleton />
          </ContainerModule>
        ) : (
          <ContainerModule>
            {moduleItem()?.kind_content === "quiz" && (
              <QuizzLength>
                Pergunta {contQuiz} de {moduleItem("module").quizzes_amount}
              </QuizzLength>
            )}
            <ContentModule
              titleCourse={titleCourse}
              titleSubmodule={
                courseModules[contModule]?.submodules[contSubMod]?.name
              }
              contents={moduleItem("contents")}
              answer={(selectOption) => setHasAnswer(selectOption)}
              isCorrect={isCorrect}
            />
            <Flex mt={4} justifyContent="flex-end" spaceChildren={3}>
              <Button
                name="Voltar"
                variant={manageClasses(
                  userStatus?.status === "finished"
                    ? "completedTransparent"
                    : "inProgressTransparent",
                )}
                action={backSlide}
              />
              {moduleItem()?.kind_content === "quiz" &&
              !moduleItem()?.answers.find((answer) => answer.chosen) &&
              isCorrect === null ? (
                <Button
                  name="Responder"
                  action={() => verifyAnswer(hasAnswer)}
                  variant={manageClasses("inProgress", {
                    disabled: !hasAnswer,
                  })}
                />
              ) : isFinalized ? (
                pageInCourse.state?.module ? (
                  <Button
                    name={
                      style.variables.child_profiles?.length > 0
                        ? "visualizar"
                        : "Ver Cursos"
                    }
                    variant={manageClasses(
                      userStatus?.status === "finished"
                        ? "completed"
                        : "inProgress",
                    )}
                    action={() =>
                      history.push(`${urls.ROUTES.ACADEMY}`, { pageTitle })
                    }
                  />
                ) : (
                  <Button
                    name="Finalizar"
                    variant={manageClasses(
                      userStatus?.status === "finished"
                        ? "completed"
                        : "inProgress",
                    )}
                    action={finalizeModule}
                    isLoading={isLoadingModule || isLoadingFinalize}
                    disabled={
                      (!videoIsFinished &&
                        isVideo &&
                        (style.names.nameApp === "Pintou Parceria Suvinil" ||
                          style.names.nameApp === "somosmachado" ||
                          style.names.nameApp === "Serquip mg" ||
                          style.names.nameApp === "sample")) ||
                      isLoadingModule ||
                      isLoadingFinalize
                    }
                  />
                )
              ) : (
                <Button
                  name="Próximo"
                  variant={manageClasses(
                    userStatus?.status === "finished"
                      ? "completed"
                      : "inProgress",
                  )}
                  action={nextSlide}
                  isLoading={isLoadingModule || isLoadingFinalize}
                  disabled={
                    (userStatus?.status !== "finished" &&
                      !videoIsFinished &&
                      isVideo &&
                      (style.names.nameApp === "Pintou Parceria Suvinil" ||
                        style.names.nameApp === "somosmachado" ||
                        style.names.nameApp === "Serquip mg" ||
                        style.names.nameApp === "sample")) ||
                    isLoadingModule ||
                    isLoadingFinalize
                  }
                />
              )}
            </Flex>
          </ContainerModule>
        )}
      </Flex>
    </Portal>
  );
};

export default Module;
