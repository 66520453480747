import styled from "styled-components";
import css from "@styled-system/css";
import theme from "styles/theme";

export const ContainerSearch = styled("div")(
  css({
    display: "flex",
    gridArea: "search",
    position: "relative",
    flex: 1,
    alignItems: "center",

    "> svg": {
      position: "absolute",
      "&.magnifier": {
        left: 10,
        opacity: "0.8",
      },
    },
  }),
  ({ filled, isBein }) =>
    css({
      "> svg": {
        "&.magnifier": {
          path: {
            fill: filled && !isBein ? "primaryColor" : "#FFF",
          },
        },
      },
    }),
);

export const InputSearch = styled("input")(
  css({
    fontFamily: "textLight",
    height: 40,
    border: "none",
    width: "100%",
    borderRadius: 8,
    padding: "10px 10px 10px 40px",
    fontSize: 16,
    margin: "16px 0 16px 0",
    "::placeholder": {
      color: "#fff",
    },
    "@media (max-width: 767px)": {
      margin: 0,
      width: 300,
    },
  }),
  ({ value, isBein }) =>
    value
      ? css({
          color: isBein ? "white" : "backText",
          backgroundColor: isBein ? "#87B0B4" : "#f2f2f2",
          borderRadius: "8px 0px 0px 8px",
        })
      : css({
          backgroundColor: "secondaryColor",
          opacity: isBein ? "100" : "0.35",
          color: "white",
        }),
);

export const ContainerInputIcons = styled("div")(({ isBein }) =>
  css({
    width: 30,
    height: 40,
    display: "flex",
    alignItems: "center",
    paddingRight: "10px",
    backgroundColor: isBein ? "#87B0B4" : "#f2f2f2",
    borderRadius: "0px 8px 8px 0px",
    justifyContent: "space-between",
    "> svg": {
      cursor: "pointer",
      "&.tagsInput": {
        path: {
          stroke: "gray",
        },
      },
      "&.clearInput": {
        path: {
          stroke: "gray",
        },
      },
      "&:hover": {
        opacity: "0.4",
      },
    },
  }),
);

export const SelectTags = styled("div")(
  css({
    marginRight: 3,
    position: "relative",
    ":hover": {
      opacity: 0.6,
      cursor: "pointer",
    },
    "@media (max-width: 1024px)": {
      display: "none",
    },
  }),
  ({ unviewdNotifications }) =>
    unviewdNotifications &&
    css({
      ":before": {
        right: 0,
        width: 8,
        bottom: 0,
        height: 8,
        content: "",
        borderRadius: "50%",
        position: "absolute",
        boxSizing: "content-box",
        backgroundColor: "yellow",
        border: `2px solid secondaryColor`,
      },
    }),
);

export const PopoverStyled = styled("Popover")(
  css({
    marginTop: 15,
    border: "none",
    borderRadius: 4,
    maxWidth: "300px",
    backgroundColor: "#FFF",
    boxShadow:
      "0px 2px 2px rgba(0, 0, 0, 0.24), 0px 0px 2px rgba(0, 0, 0, 0.12)",
  }),
);

export const ContainerSelectedTags = styled("div")(
  css({
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
  }),
);

export const ContainerTags = styled("div")(
  css({
    display: "flex",
    flexWrap: "wrap",
    margin: "0px 16px 16px",
    alignItems: "flex-start",
    justifyContent: "center",
  }),
);

export const ContentTag = styled("div")(
  css({
    cursor: "pointer",
    padding: "5px 16px",
    textAlign: "center",
    borderRadius: "23px",
    margin: "0px 4px 12px",
    border: `1px solid ${theme.colors.grayDefaultColor}`,

    fontWeight: 300,
    fontSize: "16px",
    lineHeight: "24px",
    fontStyle: "normal",
    color: "charcoalGrey",

    transition: "0.5s",

    "&:hover": {
      color: "#FFF",
      opacity: "0.8",
      borderColor: "secondaryColor",
      backgroundColor: "secondaryColor",
    },
  }),
  ({ tagActive }) =>
    tagActive > -1 &&
    css({
      color: "#FFF",
      borderColor: "secondaryColor",
      backgroundColor: "secondaryColor",
    }),
);
