import styled from "styled-components";
import theme from "styles/theme";

export const FistLineContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    >svg#icon-close{
      width: 0.65rem;
      height: 0.65rem;
      path {
          stroke: #808080;
          stroke-width: 2;
      }
      position: relative;
      right: -9rem;
      bottom: 1.5rem;
      cursor: pointer;

      &.block{
        cursor: not-allowed;
      }
    }

    >svg#icon-back{
      width: 0.75rem;
      height: 0.75rem;
      path {
          stroke: ${theme.colors.primaryColor};
          stroke-width: 2;
      }
      cursor: pointer;
      position: relative;
      left: -4rem;

      &.block{
        cursor: not-allowed;
      }
    }
`
export const TitleModal = styled.p`
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-weight: 700;
  margin-top: 1.5rem;

  color: #000000;
  font-family: "RubikRegular";
  max-width: 26rem;
  text-align: center;
`

export const TitleModalStep2 = styled.span`
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: 600;

  color: #404040;
  font-family: "RubikRegular";
  max-width: 26rem;
`

export const SubtitleModal = styled.p`
  font-size: 0.88rem;
  line-height: 1rem;

  color: #A2A2A2;
  font-family: "Rubik", sans-serif;
  max-width: 26rem;
  text-align: center;
`

export const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-family: "RubikRegular", sans-serif;
  font-size: 1rem;
  max-width: 24rem;
`

export const ProductContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-family: "RubikRegular", sans-serif;
  font-size: 1rem;
  max-width: 24rem;
  margin: 1rem 0;
`

export const ProductImage = styled.img`
  height: 10rem;
  width: 9.75rem;
  object-fit: contain;
`

export const ProductDescription = styled.div`
  min-height: 4.125rem;
  width: 15.25rem;
  text-align: left;

  font-family: "RubikRegular", sans-serif;
  font-size: 1rem;
  color: #A2A2A2;
  line-height: 1.5rem;

  >span{
    color: ${theme.colors.primaryColor};
    font-size: 1.15rem;
    font-weight: 600;
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap:1rem;
  margin-top: 1rem;
`

export const BodyForm = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  gap: 1rem;
  min-width: 25rem;

  div#input-container{
    min-width: 100%;
    align-items: start;
    label{
      font-size: 0.95rem;
      font-family: "RubikRegular", sans-serif;
      font-weight: 600;
    }
    >div{
      width: 100%;
      input{
        background-color: #F3F3F3;
        max-height: 2.5rem;
        border-color: transparent;
        font-size: 1rem;

        ::placeholder{
          color: #A2A2A2;
          font-size: 1rem;
        }
      }
    }

    select{
      background-color: #F3F3F3;
      max-height: 2.5rem;
      border-color: transparent;
      font-size: 1rem;

      ::placeholder{
        color: #A2A2A2;
        font-size: 1rem;
      }
    }
  }

  >button{
    border-radius: 35px;
    padding: 1.2rem 0;
    margin: 1rem auto 0 auto;
    width: 15.5rem;
    line-height: 0;
  }
`

export const NumberContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;

  >label{
    >input{
      min-width: 1rem;
      width: 1rem;
      height: 1rem;
    }

    >span{
      font-size: 0.8rem;
      font-family: "RubikRegular", sans-serif;
      color: #8F99B0;
    }

    svg.checked-icon{
      width: 12px !important;
      left: 2px !important;
      top: 7px !important;
    }
  }
`
export const ContainerModalStep3 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  gap: 1rem;

  >div:first-child{
    margin-top: 0;
  }
`

export const SubtitleModalStep3 = styled.span`
  font-size: 1.1rem;
  color: #A2A2A2;
  font-family: "Rubik", sans-serif;
  max-width: 20rem;
  text-align: center;
  margin: -1rem 0 1rem 0;
  line-height: 1.3rem;
`
