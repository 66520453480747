/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React from "react";
import { useIntl } from "react-intl";
import urls from "utils/constants/urls";
import { useAnalytics } from "utils/hooks";
import { convertToSlug } from "helpers/sharedHelpers";
import { useParams, Link, useHistory } from "react-router-dom";

import Flex from "components/core/Grid/Flex";
import { GoBackButton } from "containers/Mural/Publication/PublicationStyle";

import { ReactComponent as GoBackIcon } from "assets/img/icons/goBackIcon.svg";
import { ReactComponent as ArquivesIcons } from "assets/img/icons/foldersIcons.svg";
import { ReactComponent as DownloadIcon } from "assets/img/icons/downloadIcon.svg";
import { ReactComponent as FilePdf } from "assets/img/icons/filePdf.svg";
import { ReactComponent as FileJpeg } from "assets/img/icons/fileJpeg.svg";
import { ReactComponent as FileDoc } from "assets/img/icons/fileDoc.svg";
import { ReactComponent as FilePpt } from "assets/img/icons/filePpt.svg";
import { ReactComponent as FilePrezi } from "assets/img/icons/filePrezi.svg";
import { ReactComponent as FileXls } from "assets/img/icons/fileXls.svg";
import { ReactComponent as NotFilesFound } from "assets/img/icons/notFilesFound.svg";

import SkeletonFiles from "./SkeletonFiles";
import NotFoundGeneric from "../NotFoundGeneric";

import * as S from "./ManagerFilesStyle";

useAnalytics("screen_directory_files");

const ManagerFiles = ({ folders, repository, isLoading, page }) => {
  const intl = useIntl();
  const { name } = useParams();
  const history = useHistory();

  function returnIconFromFormatFile(format) {
    const formatsXls = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.oasis.opendocument.spreadsheet",
      ".xls",
      ".xlsm",
      ".csv",
      ".xml",
      "text/csv",
      "application/vnd.ms-excel.sheet.macroenabled.12",
      "application/vnd.ms-excel.sheet.macroEnabled.12",
      "unknow",
    ];
    const formatsDoc = [
      ".txt",
      ".odt",
      "application/vnd.oasis.opendocument.text",
      "text/plain",
      ".rtf",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      ".docx",
      ".doc",
      "application/msword",
      "text/html",
      "application/rtf",
    ];
    const formatsPpt = [
      ".ppt",
      ".pptx",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "application/vnd.ms-powerpoint",
      "application/vnd.oasis.opendocument.presentation",
      ".form",
    ];
    const formatsJpg = ["image/png", "image/jpeg", "image/gif", ".jpg"];
    const formatsPrezi = [".prezi"];
    if (formatsXls.includes(format)) {
      return <FileXls />;
    }
    if (formatsDoc.includes(format)) {
      return <FileDoc />;
    }
    if (formatsPpt.includes(format)) {
      return <FilePpt />;
    }
    if (formatsJpg.includes(format)) {
      return <FileJpeg />;
    }
    if (formatsPrezi.includes(format)) {
      return <FilePrezi />;
    }
    return <FilePdf />;
    //   case 'application/pdf':
  }

  const Files = () => (
    <Flex flexDirection="column">
      {name && (
        <>
          <S.BoxButton>
            <GoBackButton onClick={() => history.goBack()}>
              <GoBackIcon />
            </GoBackButton>
          </S.BoxButton>
          <S.Breadcrumb>
            <S.BreadcrumbItem to={`${urls.ROUTES.FILES}/pastas`}>
              Arquivos
            </S.BreadcrumbItem>
            {repository?.path_map?.map((path, idx) => (
              <S.BreadcrumbItem
                to={`${urls.ROUTES.FILES}/${convertToSlug(path.name)}/${
                  path.id
                }`}
                key={Number(idx)}
              >
                {path.name}
              </S.BreadcrumbItem>
            ))}
          </S.Breadcrumb>
        </>
      )}

      <Flex flexDirection="column">
        {repository?.repositories?.length > 0 &&
          repository.repositories.map((repo) => {
            const { id, name_folder, user } = repo;

            return (
              <S.LinkFolder
                to={`${urls.ROUTES.FILES}/${convertToSlug(name)}/${id}`}
              >
                <S.ItemFolder key={id} spaceChildren={4} p={3}>
                  <S.SubFolder>
                    <ArquivesIcons />
                  </S.SubFolder>
                  <Flex width={1} flexDirection="column">
                    <S.SubTitle>{name_folder}</S.SubTitle>
                    {user && <S.NameDetails>{user.name}</S.NameDetails>}
                  </Flex>
                </S.ItemFolder>
              </S.LinkFolder>
            );
          })}
        {repository?.repository_attachment?.length > 0
          ? repository.repository_attachment.map((fileItem) => {
              const { id, file, name_file, link, user, format_file } = fileItem;
              return (
                <S.ItemFolder key={id} spaceChildren={4} p={3}>
                  <S.LinkFile
                    href={`${link || file.url}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {returnIconFromFormatFile(format_file)}
                  </S.LinkFile>
                  <Flex width={1} flexDirection="column">
                    <S.LinkFile
                      href={`${link || file.url}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <S.SubTitle>{name_file}</S.SubTitle>
                    </S.LinkFile>
                    {user && <S.NameDetails>{user.name}</S.NameDetails>}
                  </Flex>
                  <S.LinkFile
                    href={`${file.url}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    download={name_file}
                  >
                    <DownloadIcon />
                  </S.LinkFile>
                </S.ItemFolder>
              );
            })
          : !folders && (
              <S.ContainerNotFilesFound flexDirection="column">
                <NotFilesFound />
                {intl.messages["arquives.textPage.notFilesFoundAtSearch"]}
              </S.ContainerNotFilesFound>
            )}
      </Flex>

      {page === 1 && isLoading ? (
        <S.BodyFiles spaceChildren={4} p={3} flexWrap="wrap">
          <SkeletonFiles />
        </S.BodyFiles>
      ) : folders?.length > 0 ? (
        <S.BodyFiles spaceChildren={4} p={3} flexWrap="wrap">
          {folders.map((folder) => {
            const { id, name_folder } = folder;
            return (
              <Link
                title={`${intl.messages["general.textPage.acessToFolder"]} ${name_folder}`}
                to={`${urls.ROUTES.FILES}/${convertToSlug(name_folder)}/${id}`}
              >
                <S.Folder key={id}>
                  <ArquivesIcons />
                </S.Folder>
                <S.FolderName>{name_folder}</S.FolderName>
              </Link>
            );
          })}
        </S.BodyFiles>
      ) : (
        !repository && (
          <NotFoundGeneric
            textPage={intl.messages["arquives.textPage.notFilesFound"]}
          />
        )
      )}
    </Flex>
  );

  return <Files />;
};

export default ManagerFiles;
