import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  getProducts: [""],
  getProductsSuccess: ["payload", "isLoading"],
  getProductsFail: ["isLoading", "error"],

  getDetailsProduct: ["id"],
  getDetailsProductSuccess: ["payload", "isLoading"],
  getDetailsProductFail: ["isLoading", "error"],

  buyProduct: ["payload"],
  buyProductSuccess: ["payload", "isLoading"],
  buyProductFail: ["isLoading", "error"],

  buyProductV2: ["payload"],
  buyProductV2Success: ["payload", "isLoading"],
  buyProductV2Fail: ["isLoading", "error"],

  confirmPurchase: ["payload"],
  confirmPurchaseSuccess: ["payload", "isLoading"],
  confirmPurchaseFail: ["isLoading", "error"],

  getRescue: [""],
  getRescueSuccess: ["payload", "isLoading"],
  getRescueFail: ["isLoading", "error"],

  getRescueDespatched: ["payload"],
  getRescueDespatchedSuccess: ["payload", "isLoading"],
  getRescueDespatchedFail: ["payload", "isLoading"],

  getRescueNotDespatched: ["payload"],
  getRescueNotDespatchedSuccess: ["payload", "isLoading"],
  getRescueNotDespatchedFail: ["payload", "isLoading"],
});
const INITIAL_STATE = {
  products: [],
  product: {},
  rescue: {},
  successPurchase: false,
  isLoading: true,
  hasMore: true,
  page: 1,

  productsDespatched: [],
  paginationDespatched: {},
  isLoadingDespatched: true,
  hasMoreDespatched: true,
  pageDespatched: 1,

  productsNotDespatched: [],
  paginationNotDespatched: {},
  isLoadingNotDespatched: true,
  hasMoreNotDespatched: true,
  pageNotDespatched: 1,

  productV2: {},
  isLoadingProductV2: false,
  userData: {},
  isLoadingConfirmPurchase: false,
};

const getProducts = (state) => ({
  ...state,
  isLoading: true,
});

const getProductsSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});
const getProductsFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error,
});

const getDetailsProduct = () => ({ ...INITIAL_STATE });
const getDetailsProductSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});
const getDetailsProductFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error,
});

const buyProduct = (state) => ({ ...state, isLoading: true });
const buyProductSuccess = (state) => ({
  ...state,
  successPurchase: true,
  isLoading: false,
});
const buyProductFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error,
});

const confirmPurchase = (state) => ({ ...state, isLoadingConfirmPurchase: true });
const confirmPurchaseSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoadingConfirmPurchase: false,
  successPurchase: true,
});
const confirmPurchaseFail = (state, error) => ({
  ...state,
  isLoadingConfirmPurchase: false,
  ...error,
});

const buyProductV2 = (state) => ({ ...state, isLoadingProductV2: true });
const buyProductV2Success = (state, payload) => ({
  ...state,
  ...payload,
  isLoadingProductV2: false,
});
const buyProductV2Fail = (state, error) => ({
  ...state,
  isLoadingProductV2: false,
  ...error,
});

const getRescue = (state) => ({ ...state });
const getRescueSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});
const getRescueFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error,
});

const getRescueDespatched = (state) => ({
  ...state,
  isLoadingDespatched: true,
});

const getRescueDespatchedSuccess = (state, { productsDespatched, paginationDespatched, hasMoreDespatched, pageDespatched }) => ({
  ...state,
  productsDespatched,
  hasMoreDespatched,
  pageDespatched,
  paginationDespatched,
  error: null,
  isLoadingDespatched: false,
});
const getRescueDespatchedFail = (state, error) => ({
  ...state,
  productsDespatched: [],
  paginationDespatched: {},
  error,
  isLoadingDespatched: false,
});

const getRescueNotDespatched = (state) => ({
  ...state,
  isLoadingDespatched: true,
});

const getRescueNotDespatchedSuccess = (state, { productsNotDespatched, paginationNotDespatched, hasMoreNotDespatched, pageNotDespatched }) => ({
  ...state,
  productsNotDespatched,
  hasMoreNotDespatched,
  pageNotDespatched,
  paginationNotDespatched,
  error: null,
  isLoadingNotDespatched: false,
});
const getRescueNotDespatchedFail = (state, error) => ({
  ...state,
  productsNotDespatched: [],
  paginationNotDespatched: {},
  error,
  isLoadingNotDespatched: false,
});

export default createReducer(INITIAL_STATE, {
  [Types.GET_PRODUCTS]: getProducts,
  [Types.GET_PRODUCTS_SUCCESS]: getProductsSuccess,
  [Types.GET_PRODUCTS_FAIL]: getProductsFail,

  [Types.GET_DETAILS_PRODUCT]: getDetailsProduct,
  [Types.GET_DETAILS_PRODUCT_SUCCESS]: getDetailsProductSuccess,
  [Types.GET_DETAILS_PRODUCT_FAIL]: getDetailsProductFail,

  [Types.BUY_PRODUCT]: buyProduct,
  [Types.BUY_PRODUCT_SUCCESS]: buyProductSuccess,
  [Types.BUY_PRODUCT_FAIL]: buyProductFail,

  [Types.BUY_PRODUCT_V2]: buyProductV2,
  [Types.BUY_PRODUCT_V2_SUCCESS]: buyProductV2Success,
  [Types.BUY_PRODUCT_V2_FAIL]: buyProductV2Fail,

  [Types.CONFIRM_PURCHASE]: confirmPurchase,
  [Types.CONFIRM_PURCHASE_SUCCESS]: confirmPurchaseSuccess,
  [Types.CONFIRM_PURCHASE_FAIL]: confirmPurchaseFail,

  [Types.GET_RESCUE]: getRescue,
  [Types.GET_RESCUE_SUCCESS]: getRescueSuccess,
  [Types.GET_RESCUE_FAIL]: getRescueFail,

  [Types.GET_RESCUE_DESPATCHED]: getRescueDespatched,
  [Types.GET_RESCUE_DESPATCHED_SUCCESS]: getRescueDespatchedSuccess,
  [Types.GET_RESCUE_DESPATCHED_FAIL]: getRescueDespatchedFail,

  [Types.GET_RESCUE_NOT_DESPATCHED]: getRescueNotDespatched,
  [Types.GET_RESCUE_NOT_DESPATCHED_SUCCESS]: getRescueNotDespatchedSuccess,
  [Types.GET_RESCUE_NOT_DESPATCHED_FAIL]: getRescueNotDespatchedFail,
});
