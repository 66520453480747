/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef-init */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { LOGIN_SAML } from "utils/constants/endpoints";
import { Input, CheckBox, InputMask } from "components/core/Form/Form";
import SignUp from "components/presentation/SignUp";
import Flex from "components/core/Grid/Flex";
import Button from "components/core/Button/Button";
import { PageTitle } from "components/core/Typography/Typography";
import { validateEmail, history } from "helpers/sharedHelpers";
import { Creators as AuthActions } from "store/ducks/auth";
import { ReactComponent as SvgLogo } from "assets/img/Logo.svg";
import { ReactComponent as ArrowLeftIcon } from "assets/img/icons/arrow-left.svg";
import urls from "utils/constants/urls";
import { style } from "style";
import * as S from "./LoginStyle";
import {
  getToken,
  loadReCaptcha,
  validateRecaptcha,
} from "../../utils/reCaptcha";
import {
  SENIOR_LWART,
  RECOVERY_PASSWORD_FOTON,
} from "../../utils/constants/routers";

const Login = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showExternalRecoveryPassword, setExternalRecoveryPassword] =
    useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const { isLoading, showTermsOfUse } = useSelector((state) => state.auth);
  const rememberMe = localStorage.getItem("rememberMe");
  const reCaptchaKey = "6LchgG0aAAAAAMAS4A9ZQEtd_p7n2S5A5d4shOh8";
  const isVedacit = style.names.nameApp === "Vedacit";
  const isLwart = style.names.nameApp === "Lwart";
  const hasExternalRecoveryPassword = ["Lwart", "Foton"].includes(
    style.names.nameApp,
  );
  const logoResizing = ["Vedacit", "Cipa"].includes(style.names.nameApp);
  const hasLoginSso = ["MRN", "Lwart", "sample"].includes(style.names.nameApp);
  const isConnect = style.names.nameApp === "ctverso";
  const isMobile = screenWidth < 767;

  const hasExternalRegister = [
    "Pintou Parceria Suvinil",
    "Vedacit",
    "mestreconstrutor",
    "sample",
  ].includes(style.names.nameApp);

  const hasRecaptcha = [
    "Pintou Parceria Suvinil",
    "Vedacit",
    "mestreconstrutor",
  ].includes(style.names.nameApp);

  useEffect(() => {
    if (hasRecaptcha) loadReCaptcha(reCaptchaKey);
    return () => {
      if (hasRecaptcha && document.getElementById("widgetLogin"))
        window.grecaptcha.reset();
    };
  }, []);

  const handleLogin = async (event) => {
    event.preventDefault();
    let recaptchaSuccess = undefined;

    if (hasRecaptcha) {
      const token = await getToken();

      const { success: responseSucces } = await validateRecaptcha(token);

      recaptchaSuccess = responseSucces;
    } else {
      recaptchaSuccess = true;
    }
    dispatch(
      AuthActions.userAuthRequest({ email, password, recaptchaSuccess }),
    );
  };

  const handleRecoveryPassword = () => {
    if (hasExternalRecoveryPassword) {
      return setExternalRecoveryPassword(true);
    }

    if (!hasLoginSso) {
      return history.push(urls.ROUTES.FORGET_PASSWORD);
    }
  };

  const handleChangePages = () => {
    if (history.location.pathname === urls.ROUTES.SIGNUP) {
      return history.push(urls.ROUTES.LOGIN);
    }
    return setExternalRecoveryPassword(false);
  };

  useEffect(() => {
    if (style.api.samlUrl) {
      window.open(
        LOGIN_SAML,
        "_parent",
        "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600, top=30",
      );
    } else {
      setEmail(localStorage.getItem("uid"));
      localStorage.setItem("nameApp", style.api.nameProfile);
    }
  }, []);

  useEffect(() => {
    if (showTermsOfUse) {
      dispatch(AuthActions.getTermsOfUse());
    }
  }, [showTermsOfUse, dispatch]);

  useEffect(() => {
    setScreenWidth(window.innerWidth);
  }, []);

  return (
    !style.api.samlUrl && (
      <S.LoginContainer>
        <div>
          <S.ContainerArrowLeft
            showArrowLeft={
              history.location.pathname === urls.ROUTES.SIGNUP ||
              (hasExternalRecoveryPassword &&
                isMobile &&
                showExternalRecoveryPassword)
            }
            externalRecoveryPassword={hasExternalRecoveryPassword}
          >
            <ArrowLeftIcon onClick={() => handleChangePages()} />
          </S.ContainerArrowLeft>
          {showExternalRecoveryPassword && (
            <S.MessageExternalRecovery>
              Está com problemas na senha?
            </S.MessageExternalRecovery>
          )}
        </div>
        <S.LogoCompany
          logoResizing={logoResizing}
          externalRecoveryPassword={hasExternalRecoveryPassword}
        >
          {style.icons.loginIcon ? (
            <S.LogoContainer>
              <img src={style.icons.loginIcon} alt="" />

              {!isMobile && isConnect && (
                <img src={style.icons.secondaryLogonLogo} alt="" />
              )}
            </S.LogoContainer>
          ) : (
            <SvgLogo />
          )}
        </S.LogoCompany>
        {history.location.pathname === urls.ROUTES.LOGIN ? (
          <S.LoginForm>
            {showExternalRecoveryPassword ? (
              <S.ExternalRecoveryPassword>
                {showExternalRecoveryPassword && isMobile && (
                  <h4>Não se preocupe!</h4>
                )}
                <h3>Está com problemas na senha?</h3>
                <p>
                  {`${
                    isMobile ? "" : "Não se preocupe!"
                  } Para recuperar sua senha ou criar uma nova,
                  acesse a ${
                    isLwart ? "plataforma Senior" : "sua conta da Google"
                  }.`}
                </p>
                <S.ExternalRecoveryPasswordButtons>
                  <Button
                    variant={isMobile ? ["outlinedRound"] : ["outlined"]}
                    name={intl.messages["general.buttons.back"]}
                    onClick={() => setExternalRecoveryPassword(false)}
                  />
                  <Button
                    variant={isMobile ? ["round"] : ["gradient"]}
                    name={intl.messages["general.buttons.access"]}
                    onClick={() =>
                      window.open(
                        isLwart ? SENIOR_LWART : RECOVERY_PASSWORD_FOTON,
                        "_self",
                      )
                    }
                  />
                </S.ExternalRecoveryPasswordButtons>
              </S.ExternalRecoveryPassword>
            ) : (
              <S.Form onSubmit={(event) => handleLogin(event)}>
                <Flex mb={4}>
                  {style.names.nameApp === "Pintou Parceria Suvinil" ||
                  style.names.nameApp === "Vedacit" ? (
                    <InputMask
                      mask="999.999.999-99"
                      maskChar={null}
                      name="email"
                      placeholder={`${intl.messages["profile.textPage.typeYourCPF"]}`}
                      label={intl.messages["general.user"]}
                      onChange={(e) =>
                        setEmail(
                          e.target.value.replaceAll(".", "").replace("-", ""),
                        )
                      }
                      value={email}
                    />
                  ) : (
                    <Input
                      type="text"
                      name="email"
                      placeholder={`${intl.messages["profile.textPage.typeYourLogin"]}`}
                      label={intl.messages["general.user"]}
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                  )}
                </Flex>
                <Input
                  type="password"
                  name="password"
                  typePasswordShow
                  value={password}
                  placeholder={`${intl.messages["login.textPage.typeYourPassword"]}`}
                  label={intl.messages["general.password"]}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <S.BoxForgetPassword
                  externalRecoveryPassword={hasExternalRecoveryPassword}
                >
                  {style.names.nameApp !== "MRN" && (
                    <a href="#!" onClick={handleRecoveryPassword}>
                      {hasExternalRecoveryPassword && !isMobile ? (
                        <FormattedMessage id="login.textPage.forgotPasswordExternalRecovery" />
                      ) : !hasLoginSso ? (
                        <FormattedMessage id="login.textPage.forgotPassword" />
                      ) : null}
                    </a>
                  )}
                </S.BoxForgetPassword>
                <S.BoxRememberMe HtmlFor="rememberMe">
                  <CheckBox
                    id="rememberMe"
                    label={intl.messages["login.textPage.rememberMe"]}
                    labelSize="14px"
                    checked={rememberMe}
                    onChange={(e) => {
                      localStorage.setItem("rememberMe", e.target.checked);
                    }}
                  />
                </S.BoxRememberMe>

                {hasRecaptcha && (
                  <div
                    className="g-recaptcha"
                    id="widgetLogin"
                    data-sitekey={reCaptchaKey}
                    style={{ alignSelf: "center", marginTop: "20px" }}
                  />
                )}

                <Button
                  style={{
                    alignSelf: "center",
                    maxWidth:
                      hasExternalRecoveryPassword && isMobile && "205px",
                    width: hasExternalRecoveryPassword && isMobile && "100%",
                  }}
                  mt={4}
                  type="submit"
                  variant={
                    isVedacit
                      ? ["vedacit"]
                      : hasExternalRecoveryPassword && isMobile
                      ? ["round"]
                      : ["gradient"]
                  }
                  name={intl.messages["general.buttons.enter"]}
                  isLoading={isLoading}
                />
                {hasExternalRecoveryPassword && isMobile && (
                  <S.BoxForgetPassword
                    externalRecoveryPassword={hasExternalRecoveryPassword}
                    isMobile={isMobile}
                  >
                    <a href="#!" onClick={handleRecoveryPassword}>
                      <FormattedMessage id="login.textPage.forgotPasswordExternalRecovery" />
                    </a>
                  </S.BoxForgetPassword>
                )}

                {hasExternalRegister && (
                  <S.BoxSignUp>
                    <S.LinkSignUp to={urls.ROUTES.SIGNUP}>
                      {intl.messages["login.textPage.iWantToRegister"]}
                    </S.LinkSignUp>
                  </S.BoxSignUp>
                )}
              </S.Form>
            )}
          </S.LoginForm>
        ) : (
          <S.ForgetPassword>
            {history.location.pathname === urls.ROUTES.SIGNUP &&
            hasExternalRegister ? (
              <SignUp />
            ) : (
              <Flex flexDirection="column" width="90%" alignItems="center">
                <Flex>
                  <PageTitle
                    noBorder
                    title={
                      intl.messages[
                        "login.textPage.enterTheEmailAddressAssociatedWithYourAccount"
                      ]
                    }
                  />
                </Flex>
                <S.SubTitle>
                  {
                    intl.messages[
                      "login.textPage.dontWorryWeWillSendYouAMessageToRecoverYourPassword"
                    ]
                  }
                </S.SubTitle>
                <Flex width={350}>
                  <Input
                    type="email"
                    name="email"
                    error={
                      email &&
                      !validateEmail(email) &&
                      intl.messages["general.buttons.inputEmailInvalid"]
                    }
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    label={<FormattedMessage id="general.email" />}
                    placeholder={`${intl.messages["general.textPage.typeYourEmail"]}`}
                  />
                </Flex>
                <Flex flexDirection="column" spaceChildren={3} mt={4}>
                  <Button
                    name={intl.messages["general.buttons.send"]}
                    variant={
                      email && validateEmail(email) ? "none" : "disabled"
                    }
                    onClick={() =>
                      dispatch(AuthActions.forgotThePassword({ email }))
                    }
                  />
                  <Button
                    variant={["transparent"]}
                    name={intl.messages["general.buttons.back"]}
                    onClick={() => history.push(urls.ROUTES.LOGIN)}
                  />
                </Flex>
              </Flex>
            )}
          </S.ForgetPassword>
        )}
        {isMobile && isConnect && (
          <S.Footer>
            <img src={style.icons.secondaryLogonLogo} alt="" />
          </S.Footer>
        )}
      </S.LoginContainer>
    )
  );
};

export default Login;
