export const APP = "/";
export const LOGOUT = "/logout";
export const LOGIN = "/login";
export const MURAL = "/mural";
export const FAVORITE = "/favoritos";
export const DETAILS_PUBLICATION = "/detalhes-publicacao";
export const FILES = "/arquivos";
export const STORE = "/loja";
export const DETAILS_PRODUCT = "/produto";
export const MY_NOTIFICATIONS = "/minhas-notificacoes";
export const SUB_MENU_PARDINI = "/grupo-pardini";
export const OUR_COMPANIES_PARDINI = "/sub-menu";
export const OUR_COMPANIES_PUBLICATION = "/detalhes";
export const SUB_ABOUT_US = "/sobre-nos";
export const SENIOR_LWART =
  "https://platform.senior.com.br/login/?redirectTo=https%3A%2F%2Fplatform.senior.com.br%2Fplataforma%2F";
export const RECOVERY_PASSWORD_FOTON =
  "https://accounts.google.com/v3/signin/recoveryidentifier?flowName=GlifWebSignIn&dsh=S-469165991%3A1718304501121156&ddm=0";

export const PRIVATE_ROUTERS = [
  MURAL,
  FAVORITE,
  DETAILS_PUBLICATION,
  FILES,
  STORE,
  DETAILS_PRODUCT,
  MY_NOTIFICATIONS,
  SUB_MENU_PARDINI,
  OUR_COMPANIES_PARDINI,
  OUR_COMPANIES_PUBLICATION,
  SUB_ABOUT_US,
];
