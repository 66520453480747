export const teamsActivities = {
  "Pintou Parceria Suvinil": [
    { id: "consumer", value: "consumer", text: "Consumidor" },
    { id: "painter", value: "painter", text: "Pintor" },
    {
      id: "become_painter",
      value: "become_painter",
      text: "Quero me formar pintor",
    },
    { id: "clerk", value: "clerk", text: "Balconista" },
    { id: "architec", value: "architec", text: "Arquiteto / Decorador" },
  ],
  Vedacit: [],
  mestreconstrutor: [],
};

export const teamsAppDiscovery = {
  "Pintou Parceria Suvinil": [
    { id: 1, value: "1", text: "Treinamentos" },
    { id: 2, value: "2", text: "Congressos e eventos" },
    { id: 3, value: "3", text: "Site suvinil.com.br" },
    { id: 4, value: "4", text: "Indicação de vendedores" },
    { id: 5, value: "5", text: "Redes sociais" },
    { id: 6, value: "6", text: "Indicação de outros profissionais" },
    { id: 7, value: "7", text: "Outros" },
  ],
};
