/* eslint-disable no-const-assign */
/* eslint-disable no-return-assign */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import Flex from "components/core/Grid/Flex";
import Menu from "components/presentation/Menu";
import Search from "components/presentation/Search";
import HeadProfile from "components/presentation/HeadProfile";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import { style } from "style";
import urls from "utils/constants/urls";

import { Creators as UserActions } from "store/ducks/user";
import { useDispatch, useSelector } from "react-redux";
import { Creators as ModalAlertActions } from "store/ducks/modalAlert";

import { ReactComponent as ArrowRight } from "assets/img/icons/arrow-right.svg";
import { ReactComponent as BackIcon } from "assets/img/icons/backIcon.svg";
import { ReactComponent as PardiniLogo } from "assets/img/clientBag/pardiniBag.svg";

import { useCalendarContext } from "context/CalendarContexts";
import * as S from "./PortalStyle";

const Portal = ({
  children,
  sidebarContext,
  contextSearch,
  setRef,
  scrollRef,
  textBtnInSideBar = false,
  fullHeight = false,
}) => {
  const intl = useIntl();
  const location = useLocation();
  const [show, setShow] = useState(null);
  const [searchShow, setSearchShow] = useState(null);
  const [scrollValue, setScrollValue] = useState(
    sessionStorage.getItem("muralScroll") || 0,
  );
  const [showDetailsSideBar, setShowDetailsSideBar] = useState(false);
  const { sideBarDetails, setSideBarDetails } = useCalendarContext();
  const dispatch = useDispatch();

  const isBein = style.names.nameApp === "Bein";

  useEffect(() => {
    if (sideBarDetails) setSideBarDetails(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location.pathname === "/" && scrollRef) {
      setTimeout(() => {
        scrollRef.scroll({
          top: sessionStorage.getItem("muralScroll"),
          behavior: "instant",
        });
      }, 100);
    }
  }, [location, scrollRef]);

  const handleScroll = (e) => {
    if (location.pathname === "/") {
      setScrollValue(e.target.scrollTop);
      sessionStorage.setItem("muralScroll", e.target.scrollTop);
    }
  };

  const scrollToTop = () => {
    scrollRef.scroll({
      top: 0,
      behavior: "smooth",
    });
  };

  // Init - Info to Mood Survey
  const {
    userAnswer,
    moodSurveyPreview,
    moodSurveyCurrent,
    hasMoodSurveyActive,
    user,
    isLoading,
  } = useSelector((state) => state.user);

  // Requests to know if the app has mood survey and update State
  const dispatchGetMoodSurveyFromProfileAPI = useCallback(
    () => dispatch(UserActions.getMoodSurveyFromProfileAPI()),
    [dispatch],
  );

  const dispatchSetMoodSurveyFromState = useCallback(
    () =>
      dispatch(
        UserActions.getMoodSurveyFromProfileAPISuccess({
          hasMoodSurveyActive:
            sessionStorage.getItem("@Noz-hasMoodSurvey") === "true"
              ? true
              : false,
        }),
      ),
    [dispatch],
  );

  // Request to find out if the user has already responded
  const dispatchGetUserAnswer = useCallback(
    () => dispatch(UserActions.getUserAnswer()),
    [dispatch],
  );

  useEffect(() => {
    if (sessionStorage.getItem("@Noz-hasMoodSurvey") === null)
      dispatchGetMoodSurveyFromProfileAPI();
    else dispatchSetMoodSurveyFromState();
  }, [dispatchGetMoodSurveyFromProfileAPI]);

  useEffect(() => {
    if (!!hasMoodSurveyActive) {
      sessionStorage.getItem("@Noz-alreadyShow-moodSurvey") === null &&
        dispatchGetUserAnswer();
    }
  }, [dispatchGetUserAnswer, hasMoodSurveyActive]);

  // Request to get the mood survey data, in case the user has not answered
  const dispatchGetMoodSurveyCurrent = useCallback(
    () => dispatch(UserActions.getMoodSurveyCurrent()),
    [dispatch],
  );

  useEffect(() => {
    const actualDate = new Date();
    const startDate = new Date(moodSurveyPreview?.start_date);
    startDate.setHours(startDate.getHours() + 3);
    const endDate = new Date(moodSurveyPreview?.end_date);
    endDate.setHours(endDate.getHours() + 3);

    if (
      userAnswer === false &&
      moodSurveyPreview?.active &&
      actualDate >= startDate &&
      actualDate <= endDate &&
      location.pathname !== urls.ROUTES.RESEARCH_SUVINIL &&
      !isLoading
    ) {
      dispatchGetMoodSurveyCurrent();
    }
  }, [userAnswer, isLoading]);

  // Triggers the modal when the request that brings the data is finished
  useEffect(() => {
    const actualDate = new Date();
    const startDate = new Date(moodSurveyCurrent?.start_date);
    startDate.setHours(startDate.getHours() + 3);
    const endDate = new Date(moodSurveyCurrent?.end_date);
    endDate.setHours(endDate.getHours() + 3);

    if (
      moodSurveyCurrent?.active &&
      actualDate >= startDate &&
      actualDate <= endDate
    ) {
      setTimeout(() => {
        dispatch(
          ModalAlertActions.setAlertsRequest({
            isMoodSurvey: true,
            moodSurveyCurrent: moodSurveyCurrent,
          }),
        );
      }, 3000);
    }
  }, [moodSurveyCurrent]);

  // End - Info to Mood Survey

  return (
    <S.GeneralContainer>
      {style.variables.child_profiles?.length > 0 && (
        <S.PardiniHeader>
          {style.icons.loginIcon ? (
            <img src={style.icons.loginIcon} alt="Logo Login" />
          ) : (
            <PardiniLogo />
          )}
        </S.PardiniHeader>
      )}
      <S.Template
        isBein={isBein}
        search={searchShow}
        showSide={showDetailsSideBar || sideBarDetails}
        gamefication={style.variables.gamificationEnabled}
      >
        <Menu
          show={(showMenu) => setShow(showMenu)}
          search={(showSearch) => setSearchShow(showSearch)}
        />
        {contextSearch?.contextAction?.length > 0 && (
          <Search contextSearch={contextSearch} />
        )}
        <HeadProfile />
        <S.MainContent
          show={show}
          ref={(ref) => setRef && setRef(ref)}
          sidebarContext={sidebarContext}
          onScroll={handleScroll}
          fullHeight={fullHeight}
        >
          <Flex flexDirection="column">
            <S.FlexComponentPortal>
              {!show && textBtnInSideBar && (
                <S.ButtonInSideBar
                  onClick={() => setShowDetailsSideBar(true)}
                  type="button"
                >
                  {textBtnInSideBar}
                  <ArrowRight />
                </S.ButtonInSideBar>
              )}
            </S.FlexComponentPortal>
            {children}
          </Flex>
          {location.pathname === "/" && (
            <S.ScrollTopButton onClick={scrollToTop} show={scrollValue > 0}>
              {intl.messages["general.buttons.backToTop"]}
            </S.ScrollTopButton>
          )}
        </S.MainContent>
        {sidebarContext && (
          <S.Sidebar
            showSide={showDetailsSideBar || sideBarDetails}
            show={show}
          >
            {!show && (showDetailsSideBar || sideBarDetails) && (
              <S.ContainerOutSideBar>
                <S.ButtonOutSideBar
                  onClick={() => {
                    setShowDetailsSideBar(false);
                    setSideBarDetails(false);
                  }}
                  type="button"
                >
                  <BackIcon />
                </S.ButtonOutSideBar>
              </S.ContainerOutSideBar>
            )}
            {sidebarContext}
          </S.Sidebar>
        )}
      </S.Template>
    </S.GeneralContainer>
  );
};

Portal.defaultProps = {
  contextSearch: {
    contextName: "",
    contextAction: "",
    contextTags: [],
  },
  children: {},
};

Portal.propTypes = {
  children: PropTypes.node,
  sidebarContext: PropTypes.element,
  contextSearch: PropTypes.object,
  setRef: PropTypes.func,
  PageTitleContentMain: PropTypes.string,
};

export default Portal;
