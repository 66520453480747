/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { useIntl } from "react-intl";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { PageTitle } from "components/core/Typography/Typography";

import { ReactComponent as IconClose } from "assets/img/icons/iconClose.svg";
import { ReactComponent as IconSearch } from "assets/img/icons/magnifierIcon.svg";
import { ReactComponent as IconFilterTags } from "assets/img/icons/filterTagsIcon.svg";
import { style } from "style";
import * as S from "./SearchStyle";

const Search = ({ contextSearch }) => {
  const [searchString, setSearchString] = useState("");
  const [previousSearch, setPreviousSearch] = useState("");
  const [tagsSelected, setTagsSelected] = useState([]);
  const { contextName, contextAction } = contextSearch;
  const { user: currentUser } = useSelector(({ user }) => user);
  const isBein = style.names.nameApp === "Bein";
  const { searchingFor: searchingForMural } = useSelector(
    (state) => state.mural,
  );
  const { searchingFor: searchingForFiles } = useSelector(
    (state) => state.files,
  );
  const intl = useIntl();

  useEffect(() => {
    switch (contextName) {
      case "Mural":
        setSearchString(searchingForMural);
        break;
      case "Arquivos":
        setSearchString(searchingForFiles);
        break;
      default:
        setSearchString("");
    }
    // eslint-disable-next-line
  }, []);

  const clearInput = () => {
    setSearchString("");
    contextAction({ valueText: "", tags: [] });
    setTagsSelected([]);
  };

  const selectedTag = (tagName) => {
    const tags = tagsSelected;
    const removeTagPosition = tags.indexOf(tagName);
    if (removeTagPosition > -1) {
      tags.splice(removeTagPosition, 1);
    } else {
      tags.push(tagName);
    }

    setTagsSelected(tags);
    contextAction({ valueText: searchString, tags: tagsSelected });
  };

  const selectedTags = (
    <S.PopoverStyled>
      <S.ContainerSelectedTags>
        <PageTitle noBorder title="Filtrar por tag?" fontSize={2} />
        <S.ContainerTags>
          {currentUser.tags?.map((tag) => (
            <S.ContentTag
              key={tag.id}
              tagActive={tagsSelected?.indexOf(tag.name)}
              onClick={() => selectedTag(tag.name)}
            >
              {tag.name}
            </S.ContentTag>
          ))}
        </S.ContainerTags>
      </S.ContainerSelectedTags>
    </S.PopoverStyled>
  );

  useEffect(() => {
    let delaySearchString;
    if (
      searchString.trim().length > 0 ||
      previousSearch !== "" //in which case it is erasing a search
    ) {
      delaySearchString = setTimeout(() => {
        contextAction({ valueText: searchString.trim(), tags: tagsSelected });
      }, 500);
    }
    return () => clearTimeout(delaySearchString);
  }, [searchString, previousSearch, contextAction, tagsSelected]);

  return (
    <S.ContainerSearch filled={searchString} isBein={isBein}>
      <IconSearch className="magnifier" />
      <S.InputSearch
        title={intl.messages[`general.textPage.searchPublication`]}
        value={searchString}
        isBein={isBein}
        onChange={(e) => {
          setPreviousSearch(searchString);
          setSearchString(e.target.value);
        }}
        placeholder={intl.messages[`general.textPage.searchPublication`]}
      />
      {searchString && (
        <S.ContainerInputIcons isBein={isBein}>
          <IconClose className="clearInput" onClick={clearInput} />
          {/* Attention: Hidden tags */}
          {/* <OverlayTrigger
            rootClose
            trigger="click"
            placement="bottom"
            overlay={selectedTags}
          >
            <IconFilterTags className="tagsInput" />
          </OverlayTrigger> */}
        </S.ContainerInputIcons>
      )}
    </S.ContainerSearch>
  );
};

export default Search;
