import React, { useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import Portal from "templates/Portal";
import ManagerFiles from "components/presentation/ManagerFiles";

import { Creators as FilesActions } from "store/ducks/files";

const FilesDetails = () => {
  const { repository, isLoading } = useSelector((state) => state.files);
  const { id } = useParams();
  const dispatch = useDispatch();
  const intl = useIntl();

  const getFilesFolder = useCallback(
    ({ id: idFolder }) => {
      dispatch(FilesActions.getFilesFolder(idFolder));
    },
    [dispatch],
  );

  useEffect(() => {
    getFilesFolder({ id });
  }, [getFilesFolder, id]);

  return (
    <Portal
      contextSearch={{ contextName: intl.messages["menu.files"] }}
      sidebar={false}
      fullHeight
    >
      {!isLoading && <ManagerFiles repository={repository} />}
    </Portal>
  );
};

export default FilesDetails;
