import { createActions, createReducer } from "reduxsauce";

export const { Types, Creators } = createActions({
  getUserRequest: ["payload"],
  getUserSuccess: ["payload", "isLoading"],
  getUserFail: ["isLoading", "error"],

  getUserRequestOrigin: ["payload"],

  getUserToEditRequest: [],

  getNotViewdNotifications: [],
  getNotViewdNotificationsSuccess: ["payload", "isLoading"],
  getNotViewdNotificationsFail: ["isLoading", "error"],

  getTerms: [],
  getTermsSuccess: ["payload", "isLoading"],
  getTermsFail: ["isLoading", "error"],

  getPrivacyPolicies: [],
  getPrivacyPoliciesSuccess: ["payload", "isLoading"],
  getPrivacyPoliciesFail: ["isLoading", "error"],

  getPersonalQuestions: [],
  getPersonalQuestionsSuccess: ["payload", "isLoading"],
  getPersonalQuestionsFail: ["isLoading", "error"],

  getTutorialToken: [],
  getTutorialTokenSuccess: ["payload", "isLoading"],
  getTutorialTokenFail: ["isLoading", "error"],

  updateDataUser: ["payload"],
  updateDataUserSuccess: ["payload", "isLoading"],
  updateDataUserFail: ["isLoading", "error"],

  setUpdateUserInAddressConfirmation: ["payload"],

  updatePassword: ["payload"],
  updatePasswordSuccess: ["payload", "isLoading"],
  updatePasswordFail: ["isLoading", "error"],

  deleteUserAccount: ["payload"],

  getUserAnswer: ["payload"],
  getUserAnswerSuccess: ["payload"],
  getUserAnswerFail: ["error"],

  getMoodSurveyCurrent: ["payload"],
  getMoodSurveyCurrentSuccess: ["payload"],
  getMoodSurveyCurrentFail: ["error"],
  postMoodSurveyAnswer: ["payload"],
  postMoodSurveyAnswerSuccess: ["payload"],
  postMoodSurveyAnswerFail: ["error"],

  visualizationMoodSurveyCurrent: ["payload"],

  getMoodSurveyFromProfileAPI: ["payload"],
  getMoodSurveyFromProfileAPISuccess: ["payload"],
  getMoodSurveyFromProfileAPIFail: ["error"],

  clearMoodSurvey: ["payload"],
});
const INITIAL_STATE = {
  user: {},
  updateUserInaddressConfirmation: false,
  terms: {},
  personalQuestions: [],
  notViewdNotifications: 0,
  isLoading: false,
  isLoadingUpdate: false,
  currentPassError: false,
  isLoadingPassword: false,
  userAnswer: null,
  moodSurveyPreview: {},
  moodSurveyCurrent: {},
  moodSurveySuccessfullyAnswered: false,
  hasMoodSurveyActive: null,
  originRequest: "",
};

const getUserRequest = (state) => ({
  ...INITIAL_STATE,
  ...state,
  isLoading: true,
});

const getUserRequestOrigin = (state, payload) => {
  const convertedPayload = payload?.payload;
  return { ...state, ...convertedPayload };
};

const getUserToEditRequest = (state) => ({ ...state });

const getUserSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
  originRequest: "",
});
const getUserFail = (state, error) => ({
  ...state,
  isLoading: false,
  originRequest: "",
  ...error,
});

const getNotViewdNotifications = (state) => ({ ...state });

const getNotViewdNotificationsSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});
const getNotViewdNotificationsFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error,
});

const getTerms = (state) => ({ ...state });

const getTermsSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});
const getTermsFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error,
});

const getPrivacyPolicies = (state) => ({ ...state });

const getPrivacyPoliciesSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});
const getPrivacyPoliciesFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error,
});

const getPersonalQuestions = (state) => ({ ...state });

const getPersonalQuestionsSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoading: false,
});
const getPersonalQuestionsFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error,
});

const getTutorialToken = (state) => ({ ...state });

const getTutorialTokenSuccess = (state, payload) => ({
  ...state,
  isLoading: false,
  ...payload,
});
const getTutorialTokenFail = (state, error) => ({
  ...state,
  isLoading: false,
  ...error,
});

const updateDateUser = (payload) => ({
  ...payload,
  isLoadingUpdate: true,
});

const updateDateUserSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoadingUpdate: false,
  updateDateUserSuccess: true,
});

const setUpdateUserInAddressConfirmation = (state) => ({
  ...state,
  updateUserInaddressConfirmation: false,
});

const updateDateUserFail = (state, error) => ({
  ...state,
  isLoadingUpdate: false,
  ...error,
});

const updatePassword = (payload) => ({
  ...payload,
  isLoadingPassword: true,
});
const updatePasswordSuccess = (state, payload) => ({
  ...state,
  ...payload,
  isLoadingPassword: false,
});
const updatePasswordFail = (state, error) => ({
  ...state,
  isLoadingPassword: false,
  ...error,
});

const deleteUserAccount = (payload) => ({
  ...payload,
});

const getUserAnswer = (payload) => ({
  ...payload,
});

const getUserAnswerSuccess = (state, payload) => ({
  ...state,
  ...payload,
});

const getUserAnswerFail = (state, error) => ({
  ...state,
  ...error,
});

const getMoodSurveyCurrent = (payload) => ({
  ...payload,
});

const getMoodSurveyCurrentSuccess = (state, payload) => ({
  ...state,
  ...payload,
});

const getMoodSurveyCurrentFail = (state, error) => ({
  ...state,
  ...error,
});

const postMoodSurveyAnswer = (payload) => ({
  ...payload,
});

const postMoodSurveyAnswerSuccess = (state, payload) => ({
  ...state,
  ...payload,
  moodSurveySuccessfullyAnswered: true,
});

const postMoodSurveyAnswerFail = (state, error) => ({
  ...state,
  ...error,
  moodSurveySuccessfullyAnswered: false,
});

const visualizationMoodSurveyCurrent = (payload) => ({
  ...payload,
});

const getMoodSurveyFromProfileAPI = (payload) => ({
  ...payload,
});

const getMoodSurveyFromProfileAPISuccess = (state, payload) => ({
  ...state,
  ...payload,
});

const getMoodSurveyFromProfileAPIFail = (state, error) => ({
  ...state,
  ...error,
});

const clearMoodSurvey = (payload) => ({
  ...payload,
  userAnswer: null,
  moodSurveyPreview: {},
  moodSurveyCurrent: {},
});

export default createReducer(INITIAL_STATE, {
  [Types.GET_USER_REQUEST]: getUserRequest,
  [Types.GET_USER_SUCCESS]: getUserSuccess,
  [Types.GET_USER_FAIL]: getUserFail,

  [Types.GET_USER_REQUEST_ORIGIN]: getUserRequestOrigin,

  [Types.GET_USER_TO_EDIT_REQUEST]: getUserToEditRequest,

  [Types.GET_NOT_VIEWD_NOTIFICATIONS]: getNotViewdNotifications,
  [Types.GET_NOT_VIEWD_NOTIFICATIONS_SUCCESS]: getNotViewdNotificationsSuccess,
  [Types.GET_NOT_VIEWD_NOTIFICATIONS_FAIL]: getNotViewdNotificationsFail,

  [Types.GET_TERMS]: getTerms,
  [Types.GET_TERMS_SUCCESS]: getTermsSuccess,
  [Types.GET_TERMS_FAIL]: getTermsFail,

  [Types.GET_PRIVACY_POLICIES]: getPrivacyPolicies,
  [Types.GET_PRIVACY_POLICIES_SUCCESS]: getPrivacyPoliciesSuccess,
  [Types.GET_PRIVACY_POLICIES_FAIL]: getPrivacyPoliciesFail,

  [Types.GET_PERSONAL_QUESTIONS]: getPersonalQuestions,
  [Types.GET_PERSONAL_QUESTIONS_SUCCESS]: getPersonalQuestionsSuccess,
  [Types.GET_PERSONAL_QUESTIONS_FAIL]: getPersonalQuestionsFail,

  [Types.GET_TUTORIAL_TOKEN]: getTutorialToken,
  [Types.GET_TUTORIAL_TOKEN_SUCCESS]: getTutorialTokenSuccess,
  [Types.GET_TUTORIAL_TOKEN_FAIL]: getTutorialTokenFail,

  [Types.UPDATE_DATA_USER]: updateDateUser,
  [Types.UPDATE_DATA_USER_SUCCESS]: updateDateUserSuccess,
  [Types.UPDATE_DATA_USER_FAIL]: updateDateUserFail,

  [Types.SET_UPDATE_USER_IN_ADDRESS_CONFIRMATION]:
    setUpdateUserInAddressConfirmation,

  [Types.UPDATE_PASSWORD]: updatePassword,
  [Types.UPDATE_PASSWORD_SUCCESS]: updatePasswordSuccess,
  [Types.UPDATE_PASSWORD_FAIL]: updatePasswordFail,

  [Types.DELETE_USER_ACCOUNT]: deleteUserAccount,

  [Types.GET_USER_ANSWER]: getUserAnswer,
  [Types.GET_USER_ANSWER_SUCCESS]: getUserAnswerSuccess,
  [Types.GET_USER_ANSWER_FAIL]: getUserAnswerFail,
  [Types.GET_MOOD_SURVEY_CURRENT]: getMoodSurveyCurrent,
  [Types.GET_MOOD_SURVEY_CURRENT_SUCCESS]: getMoodSurveyCurrentSuccess,
  [Types.GET_MOOD_SURVEY_CURRENT_FAIL]: getMoodSurveyCurrentFail,

  [Types.POST_MOOD_SURVEY_ANSWER]: postMoodSurveyAnswer,
  [Types.POST_MOOD_SURVEY_ANSWER_SUCCESS]: postMoodSurveyAnswerSuccess,
  [Types.POST_MOOD_SURVEY_ANSWER_FAIL]: postMoodSurveyAnswerFail,

  [Types.VISUALIZATION_MOOD_SURVEY_CURRENT]: visualizationMoodSurveyCurrent,

  [Types.GET_MOOD_SURVEY_FROM_PROFILE_API]: getMoodSurveyFromProfileAPI,
  [Types.GET_MOOD_SURVEY_FROM_PROFILE_API_SUCCESS]:
    getMoodSurveyFromProfileAPISuccess,
  [Types.GET_MOOD_SURVEY_FROM_PROFILE_API_FAIL]:
    getMoodSurveyFromProfileAPIFail,

  [Types.CLEAR_MOOD_SURVEY]: clearMoodSurvey,
});
