import React, { useEffect, useState, useCallback } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import Button from "../Button/Button";
import { Loading } from "../Button/ButtonStyle";

import {
  Input,
  InputMask,
  Select,
  CheckBox
} from "components/core/Form/Form";

import Flex from "components/core/Grid/Flex";

import { ReactComponent as IconClose } from "assets/img/icons/iconClose.svg";
import { ReactComponent as IconStatus } from "assets/img/icons/addressConfirmationStatus.svg";
import { ReactComponent as IconStatus2 } from "assets/img/icons/addressConfirmationStatus2.svg";
import { ReactComponent as IconToGoBack } from "assets/img/icons/toGoBack.svg";
import { ReactComponent as IconSuccess } from "assets/img/icons/success.svg";

import { Creators as ModalAlertActions } from "store/ducks/modalAlert";
import { Creators as AuthActions } from "store/ducks/auth";
import { Creators as UserActions } from "store/ducks/user";
import { Creators as StoreActions } from "store/ducks/store";

import * as AlertModalStyle from "./AlertModalStyle";
import * as S from "./AddressConfirmationStyle";
import * as SignupStyles from "../../presentation/SignUp/SignUpStyle";

export const AddressConfirmation = () => {

  const intl = useIntl();
  const dispatch = useDispatch();

  const { alerts: dataAlerts } = useSelector((state) => state.alert);
  const { user, updateUserInaddressConfirmation, isLoadingUpdate } = useSelector((state) => state.user);
  const { listState, listCities, isLoading } = useSelector((state) => state.auth);
  const { productV2, userData, isLoadingConfirmPurchase, successPurchase,
    product : { valuescore, id } } = useSelector((state) => state.store);

  const [showCities, setShowCities] = useState(false);
  const [listCitiesState, setListCitiesState] = useState(listCities);

  //Funções para mudar steps do modal

  const handleCloseModal = () => {
    dispatch(
      ModalAlertActions.setAlertsRequest({}),
    );
  };

  const handleUpdateStep = (step) => {
    dispatch(
      ModalAlertActions.setAlertsRequest({
        modalType: 'AddressConfirmation',
        modalStep: step
      }),
    );
  };


  useEffect(()=>{
    if(updateUserInaddressConfirmation) {
      handleUpdateStep(3);
      setTimeout(()=>{
        handleUpdateStep(4);
        dispatch(UserActions.setUpdateUserInAddressConfirmation());
      },3000)
    }
  },[updateUserInaddressConfirmation]);

  useEffect(()=>{
    if(successPurchase) {
      handleCloseModal();
    }
  },[successPurchase]);

  //Funções para atualização de dados do Usuário

  const updateDataUser = (e) => {
    e.preventDefault();

    form.address = {
      street: form.street,
      number: form.number,
      complement: form.complement,
      zip_code: form.zip_code,
      neighborhood: form.neighborhood,
      uf: form.state,
      city: form.city,
    };
    delete form.street;
    delete form.number;
    delete form.complement;
    delete form.zip_code;
    delete form.neighborhood;
    delete form.state;
    delete form.city;

    form.isAddressConfirmation = true;
    dispatch(UserActions.updateDataUser(form));
  };


  const updateSexGender = (genderUser) => {
    const Genders = {
      "Mulher cis": "cis_woman",
      "Mulher trans": "trans_woman",
      "Homem cis": "cis_man",
      "Homem trans": "trans_man",
      "Não binário": "not_binary",
      "Prefere não se identificar": "prefers_not_to_identify",
      default: "other",
    };

    return Genders[genderUser] || Genders.default;
  };

  const [form, setForm] = useState({});
  const [noNumber, setNoNumber] = useState(false);


  useEffect(() => {
    if (Object.keys(user).length) {
      setForm({
        ...form,
        image: user.image.url,
        name: user.name,
        email: user.email,
        sex: updateSexGender(user.sex),
        custom_gender:
          updateSexGender(user.sex) === "other" && user.sex !== "null"
            ? user.sex
            : "",
        show_gender: user.show_gender,
        datebirthday: moment(user.datebirthday).utc().format("DD-MM-YYYY"),
        bio: user.bio || "",
        interests: user.interests || "",
        skills: user.skills || "",
        contacts: [...user.contacts],
        zip_code: user.address !== null ? user.address.zip_code : "",
        street:
          user.address !== null && user.address.street !== "null"
            ? user.address.street
            : "",
        neighborhood:
          user.address !== null && user.address.neighborhood !== "null"
            ? user.address.neighborhood
            : "",
        state_id: user.state !== null ? user.state.id : "",
        state: user.state !== null ? user.state.uf : "",
        city_id: user.city !== null ? user.city.id : "",
        city: user.city !== null ? user.city.name : "",
        number: user.address !== null ? user.address.number : "",
        complement:
          user.address !== null &&
          user.address.complement !== null &&
          user.address.complement !== "null"
            ? user.address.complement
            : "",
        cnpj: user.cnpj,
      });
    }
  }, [user]);

  const handleCep = (value) => {
    setForm({ ...form, zip_code: value });

    if (value.length === 9) {
      fetch(`https://viacep.com.br/ws/${value.replace("-", "")}/json/`).then(
        (response) => {
          response.json().then((data) => {
            const { localidade, logradouro, cep, uf, bairro } = data;
            const findUf = listState.find((item) => item.uf === uf);
            setForm({
              ...form,
              city: localidade,
              street: logradouro,
              zip_code: cep,
              neighborhood: bairro,
              state_id: findUf.id,
              state: uf,
            });
          });
        },
      );
    }
  };

  const dispatchGetStateList = useCallback(
    () => dispatch(AuthActions.getStateList()),
    [dispatch],
  );
  useEffect(() => {
    if(!listState || listState?.length === 0) dispatchGetStateList();
  }, [dispatchGetStateList, listState]);

  const dispatchGetCitiesList = useCallback(
    (CitiesId) => dispatch(AuthActions.getCitiesList({ id: CitiesId })),
    [dispatch],
  );

  useEffect(() => {
    Number.isInteger(form.state_id) && dispatchGetCitiesList(form.state_id);
  }, [form.state_id]);

  const handleSendCities = (valueId) => {
    dispatchGetCitiesList(valueId);
    let new_uf;
    listState.map((state) => {
      if (state.id == valueId) new_uf = state.uf;
    });
    setForm({
      ...form,
      state_id: valueId,
      state: new_uf,
      city: "",
      city_id: "",
      street: "",
      zip_code: "",
      number: "",
      complement: "",
      neighborhood: "",
    });
    setShowCities(false);
  };

  const handleSearchCities = (nameCity) => {
    if (nameCity.length > 0) {
      const filterCities = listCities?.filter(
        (cities) =>
          cities.text?.toLowerCase().indexOf(nameCity.toLowerCase().trim()) >
            -1 && cities,
      );
      setShowCities(true);
      setListCitiesState(filterCities);
      if (
        filterCities.length === 1 &&
        filterCities[0].text === nameCity.trim()
      ) {
        setForm({ ...form, city_id: filterCities[0].id });
        setShowCities(false);
      }
    }
    setForm({ ...form, city: nameCity });
  };

  const handleClick = (cities) => {
    setShowCities(false);
    setForm({
      ...form,
      city: cities.text,
      city_id: `${cities.id}`,
    });
  };

  useEffect(() => {
    setListCitiesState(listCities);

    const findCity = listCities.find((item) => item.text === form.city);
    if (findCity !== undefined)
      setForm({
        ...form,
        city_id: findCity.id,
      });
  }, [listCities]);

  const changeContacts = (kind, newValue) => {
    const contact = form.contacts.find((contact) => contact.kind === kind);

    if (contact === undefined) {
      setForm({
        ...form,
        contacts: [
          ...form.contacts,
          {
            kind,
            contact: newValue,
          },
        ],
      });
    } else {
      setForm({
        ...form,
        contacts: form.contacts?.map((contact) =>
          contact.kind === kind
            ? {
                ...contact,
                contact: newValue,
              }
            : contact,
        ),
      });
    }
  };

  // Endereço sem número (abaixo do número)

  // <CheckBox
  //   key={'no-number'}
  //   id={'no-number'}
  //   label="Endereço sem número"
  //   checked={noNumber}
  //   onChange={({ target: { value, checked } }) =>
  //     setNoNumber(checked)
  //   }
  // />

  //Requisição que realiza a compra

  const dispatchConfirmPurchase= useCallback(
    () => dispatch(StoreActions.confirmPurchase({
      product_id: id,
      current_user: user?.id
    })), [dispatch],
  );

  const stepsMapping = {
    1: (
      <AlertModalStyle.ContainerModal>
        <S.FistLineContainer>
          <IconStatus />
          <IconClose id="icon-close" onClick={handleCloseModal} />
        </S.FistLineContainer>
        <S.TitleModal>
          {intl.messages["store.textPage.confirmYourShippingDetails"]}
        </S.TitleModal>
        <S.SubtitleModal>
          {intl.messages["store.textPage.toRedeemYourPrizeConfirmYourRegisteredDetails"]}
        </S.SubtitleModal>
        <S.AddressContainer>
          <div>
            <strong>{intl.messages["store.textPage.address"]}:</strong>{" "}
            {userData?.address}
          </div>
          {!!userData?.phone && (
            <div>
                <strong>Telefone:</strong>{" "}
                {userData.phone}
            </div>
          )}
          {!!userData?.email && (
            <div>
                <strong>E-mail:</strong>{" "}
                {userData.email}
            </div>
          )}
        </S.AddressContainer>
        <S.ButtonsContainer>
          <Button
            name={intl.messages["store.textPage.updateData"]}
            variant={["transparent", "big", "noTextTransform"]}
            onClick={() => handleUpdateStep(2)}
          />
          <Button
            name={intl.messages["general.buttons.continue"]}
            variant={["big","noTextTransform"]}
            onClick={() => handleUpdateStep(4)}
          />
        </S.ButtonsContainer>
      </AlertModalStyle.ContainerModal>
    ),
    2: (
      <AlertModalStyle.ContainerModal>
        <S.FistLineContainer>
          <IconToGoBack
            id="icon-back"
            onClick={!isLoadingUpdate ? () => handleUpdateStep(1) : () => {}}
            className={isLoadingUpdate && 'block'}
          />
          <S.TitleModalStep2>{intl.messages["store.textPage.updateYourInfo"]}</S.TitleModalStep2>
        </S.FistLineContainer>
        {isLoading ? (
          <Loading data-testid="loading-element" style={{margin: '5rem 0 5rem 0'}} />
        ) : (
          <S.BodyForm onSubmit={(e) => updateDataUser(e)}>
            <InputMask
              mask="99999-999"
              maskChar={null}
              type="text"
              name="zip_code"
              placeholder={intl.messages["profile.textPage.typeYourCEP"]}
              label={`${intl.messages["profile.cep"]}`}
              onChange={(e) => handleCep(e.target.value)}
              value={form.zip_code}
            />

            <Input
              type="text"
              name="street"
              placeholder={intl.messages["profile.textPage.typeYourAddress"]}
              label={`${intl.messages["profile.address"]}`}
              onChange={(e) => setForm({ ...form, street: e.target.value })}
              value={form.street}
            />
            <Flex>
              <S.NumberContainer>
                <Input
                  type="number"
                  name="number"
                  error={
                    form.number === "" &&
                    intl.messages["general.buttons.inputRequired"]
                  }
                  placeholder={
                    intl.messages["profile.textPage.typerYourNumber"]
                  }
                  label={`${intl.messages["profile.number"]}*`}
                  onChange={(e) =>
                    setForm({ ...form, number: e.target.value })
                  }
                  value={form.number}
                />
              </S.NumberContainer>
              <Flex ml={9}>
                <Input
                  type="text"
                  name="complement"
                  placeholder={
                    intl.messages["profile.textPage.typeYourComplement"]
                  }
                  label={`${intl.messages["profile.complement"]}`}
                  onChange={(e) =>
                    setForm({ ...form, complement: e.target.value })
                  }
                  value={form.complement}
                />
              </Flex>
            </Flex>
            <Input
              type="text"
              name="neighborhood"
              placeholder={
                intl.messages["profile.textPage.typeYourNeighborhood"]
              }
              label={`${intl.messages["profile.neighborhood"]}`}
              onChange={(e) =>
                setForm({ ...form, neighborhood: e.target.value })
              }
              value={form.neighborhood}
            />
            <Select
              value={form.state_id}
              options={listState}
              label={`${intl.messages["profile.state"]}*`}
              onChange={(e) => handleSendCities(e.target.value)}
              error={
                form.state_id === "" &&
                intl.messages["general.buttons.inputRequired"]
              }
            />
            <SignupStyles.ContentSelectCity>
              <SignupStyles.ListCities cityValidation={showCities && form.city}>
                {showCities && listCitiesState.map((cities) => (
                  <SignupStyles.ContentCities
                    key={cities.id}
                    onClick={() => handleClick(cities)}
                  >
                    {cities.text}
                  </SignupStyles.ContentCities>
                ))}
              </SignupStyles.ListCities>
              <Input
                type="text"
                name="city"
                placeholder={intl.messages["login.textPage.typeYourCity"]}
                label={`${intl.messages["profile.city"]}*`}
                onChange={(e) => handleSearchCities(e.target.value)}
                value={form.city}
                error={
                  form.city === "" &&
                  intl.messages["general.buttons.inputRequired"]
                }
                autocomplete="your_city"
              />
            </SignupStyles.ContentSelectCity>

            <Input
              name="emailContact"
              value={
                form.contacts?.find((contact) => contact.kind === "email")
                  ?.contact || ""
              }
              placeholder={`${intl.messages["profile.textPage.typeYourEmail"]}`}
              label={"E-MAIL"}
              onChange={(e) => changeContacts("email", e.target.value)}
              defaultValue="@"
            />

            <InputMask
              mask="(99) 9 9999-9999"
              maskChar={null}
              type="text"
              name="phone"
              value={
                form.contacts?.find((contact) => contact.kind === "phone")
                  ?.contact || ""
              }
              placeholder={`${intl.messages["profile.textPage.typePhone"]}`}
              label={`${intl.messages["profile.phone"]}`}
              onChange={(e) => changeContacts("phone", e.target.value)}
            />

            <Button
              name={intl.messages["store.textPage.updateInfoButton"]}
              isLoading={isLoadingUpdate}
              type="submit"
              variant={
                !form.number || !form.state_id || !form.city ?
                ["disabled"] : []
              }
            />

          </S.BodyForm>
        )}
      </AlertModalStyle.ContainerModal>
    ),
    3: (
      <AlertModalStyle.ContainerModal>
        <S.ContainerModalStep3>
          <AlertModalStyle.ContainerType type="success">
            <IconSuccess/>
          </AlertModalStyle.ContainerType>
          <S.TitleModal>
            {intl.messages["store.textPage.updatedInformationSuccessfully"]}
          </S.TitleModal>
          <S.SubtitleModalStep3>
            {intl.messages["store.textPage.waitAMomentThisWindowWillCloseAutomatically"]}
          </S.SubtitleModalStep3>
        </S.ContainerModalStep3>
      </AlertModalStyle.ContainerModal>
    ),
    4: (
      <AlertModalStyle.ContainerModal>
        <S.FistLineContainer>
          <IconStatus2 />
          <IconClose
            id="icon-close"
            onClick={!isLoadingConfirmPurchase ? () => handleCloseModal() : () => {}}
            className={isLoadingConfirmPurchase && 'block'}
          />

        </S.FistLineContainer>
        <S.TitleModal>
          {intl.messages["store.textPage.confirmYouPurchase"]}
        </S.TitleModal>
        <S.ProductContainer>
          <S.ProductImage src={productV2?.image?.url}/>
          <S.ProductDescription>
            <span>{intl.messages["store.textPage.youWantToBuy"]}</span> <br/>
            {` ${productV2?.name} `}
            {intl.messages["store.textPage.for"]}
            {` ${valuescore} `}
            {intl.messages["general.textPage.nameCoins"]}?
          </S.ProductDescription>
        </S.ProductContainer>
        <S.ButtonsContainer>
          <Button
            name={intl.messages["general.buttons.cancel"]}
            variant={
              isLoadingConfirmPurchase ?
              ["transparent", "big", "noTextTransform", "disabled"]
              : ["transparent", "big", "noTextTransform"]
            }
            onClick={() => handleUpdateStep(1)}
          />
          <Button
            name={intl.messages["general.buttons.confirm"]}
            variant={["big","noTextTransform"]}
            onClick={() => dispatchConfirmPurchase()}
            isLoading={isLoadingConfirmPurchase}
          />
        </S.ButtonsContainer>
      </AlertModalStyle.ContainerModal>
    )
  }

  return (
    <AlertModalStyle.StyledModal
      data-testid="modal-component"
      isOpen={dataAlerts?.modalType === 'AddressConfirmation'}
      type={false}
      style={{ width: "29rem" }}
    >
      {dataAlerts?.modalStep && stepsMapping[dataAlerts?.modalStep]}
    </AlertModalStyle.StyledModal>
  )
}
