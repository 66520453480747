import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { style } from "style";
import AnalyticsPageView from "../AnalyticsPageView";

const TitlePageComponent = ({ title }) => {
  const isPrio = style.names.nameApp === "PetroRio";
  const defaultTitle = isPrio ? "PRIO" : style.names.nameApp;
  return (
    <>
      <Helmet>
        <title>{title ? `${title} - ${defaultTitle}` : defaultTitle}</title>
      </Helmet>
      <AnalyticsPageView title={title} />
    </>
  );
};

TitlePageComponent.propTypes = {
  title: PropTypes.string,
};

TitlePageComponent.defaultProps = {
  title: "",
};

export default TitlePageComponent;
