/* eslint-disable react/require-default-props */
import React from "react";
import PropTypes from "prop-types";
import placeHolderUser from "assets/img/icons/placeHolderUser.png";
import { Avatar } from "./AvatarStyle";

const AvatarComponent = ({
  url,
  shadow,
  size,
  ml,
  border,
  onClick,
  title,
  style,
  client,
}) => (
  <Avatar
    data-testid="avatar-element"
    src={url || placeHolderUser}
    size={size}
    shadow={shadow}
    client={client}
    ml={ml}
    border={border}
    onClick={onClick}
    isCursorPointer={!!onClick}
    title={title}
    style={style}
    loading="lazy"
  />
);

AvatarComponent.propTypes = {
  ml: PropTypes.string,
  url: PropTypes.string,
  border: PropTypes.bool,
  shadow: PropTypes.bool,
  size: PropTypes.string,
  style: PropTypes.objectOf(React.CSSProperties),
};

export default AvatarComponent;
