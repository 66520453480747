/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useParams, useHistory, useLocation } from "react-router-dom";
import urls from "utils/constants/urls";
import Portal from "templates/Portal";
import Flex from "components/core/Grid/Flex";
import { PageTitle } from "components/core/Typography/Typography";
import Button from "components/core/Button/Button";
import Confetti from "react-confetti";

import { Creators as StoreActions } from "store/ducks/store";
import { Creators as ModalAlertActions } from "store/ducks/modalAlert";

import { ReactComponent as SuvinilBag } from "assets/img/clientBag/suvinilBag.svg";
import { ReactComponent as HubsocialBag } from "assets/img/clientBag/hubsocialBag.svg";
import { ReactComponent as EmtelBag } from "assets/img/clientBag/emtelBag.svg";
import { ReactComponent as DitoBag } from "assets/img/clientBag/ditoBag.svg";
import { ReactComponent as DepylBag } from "assets/img/clientBag/depylBag.svg";
import { ReactComponent as InterBag } from "assets/img/clientBag/interBag.svg";
import { ReactComponent as SemearBag } from "assets/img/clientBag/semearBag.svg";
import { ReactComponent as PetrorioBag } from "assets/img/clientBag/petrorioBag.svg";
import { ReactComponent as IoasysBag } from "assets/img/clientBag/ioasysBag.svg";
import { ReactComponent as BakeryBag } from "assets/img/clientBag/bakeryBag.svg";
import { ReactComponent as SaferBag } from "assets/img/clientBag/safeOrangeBag.svg";
import { ReactComponent as SmarttBotBag } from "assets/img/clientBag/smartBotBag.svg";
import { ReactComponent as CibraBag } from "assets/img/clientBag/cibraBag.svg";
import { ReactComponent as ImgSuccessSale } from "assets/newSuccessBuy.svg";
import { ReactComponent as IconCoins } from "assets/img/icons/iconCoins.svg";
import { ReactComponent as GoBackIcon } from "assets/img/icons/goBackIcon.svg";

import { manageClasses } from "helpers/sharedHelpers";
import { style } from "style";
import { useAnalytics } from "utils/hooks";

import { GoBackButton } from "containers/Mural/Publication/PublicationStyle";
import InfiniteScroll from "react-infinite-scroller";
import {
  PriceDetails,
  ProductContainer,
  ProductContent,
  ProductDescription,
  ProductImg,
  ProductName,
  TitleContainer,
  DescriptionContainer,
  DescriptionDivider,
  ProductKind,
  ContainerConfetti,
} from "./DetailsProductStyle";
import { ValuePrice } from "../StoreStyle";
import SkeletonDetailsProduct from "./SkeletonDetailsProduct";
import SidebarStore from "../SidebarStore/SidebarStore";

useAnalytics("screen_store_product_details");

const DetailsProduct = () => {
  const intl = useIntl();
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const { pageTitle } = location.state || "";
  const [isLoadingSuccess, setIsLoadingSuccess] = useState(false);
  const { product, successPurchase, isLoading, isLoadingProductV2 } = useSelector(
    (state) => state.store,
  );
  const { address, city, state, id: userId } = useSelector((state) => state.user.user);
  const {
    image,
    name,
    kind,
    valuescore,
    description,
    amount,
    limit_reached,
    default_message_to_send,
  } = product;
  const history = useHistory();
  const goBack = () => history.goBack();
  const needUpdateAdress = [
    "Pintou Parceria Suvinil",
    "Vedacit",
    "mestreconstrutor",
  ].includes(style.names.nameApp);

  const dispatchGetDetailsProduct = useCallback(
    () => dispatch(StoreActions.getDetailsProduct(id)),
    [dispatch, id],
  );
  const [isDesktop, setDesktop] = useState(window.innerWidth > 1024);
  const changeDataPosition = () => {
    setDesktop(window.innerWidth > 1024);
  };

  useEffect(() => {
    window.addEventListener("resize", changeDataPosition);
    return () => window.removeEventListener("resize", changeDataPosition);
  });

  useEffect(() => {
    dispatchGetDetailsProduct();
  }, [dispatchGetDetailsProduct]);

  const checkIfYouNeedToUpdateAddress = () => {
    if (!needUpdateAdress) {
      return false;
    }
    if (
      address === null ||
      address.neighborhood === "" ||
      address.neighborhood === "null" ||
      address.neighborhood === null ||
      address.number === "" ||
      address.number === "null" ||
      address.number === null ||
      address.street === "" ||
      address.street === "null" ||
      address.street === null ||
      address.zip_code === "" ||
      address.zip_code === "null" ||
      address.zip_code === null
    ) {
      return true;
    }

    return false;
  };

  const confirmBuy = () => {
    setIsLoadingSuccess(true);

    if(style.names.nameApp === 'Pintou Parceria Suvinil'){
      dispatch(
        StoreActions.buyProductV2({
          product_id: id,
          current_user: userId
        })
      )
      return;
    }

    const formMassage = (
      <>
        {intl.messages["store.textPage.youWantToBuy"]} {name}{" "}
        {intl.messages["store.textPage.for"]} {valuescore}{" "}
        {intl.messages["general.textPage.nameCoins"]}?
      </>
    );

    dispatch(
      ModalAlertActions.setAlertsRequest({
        title: intl.messages["modal.textPage.confirmPurchase"],
        msg: formMassage,
        buttons: [
          {
            title: intl.messages["general.buttons.cancel"],
            variant: ["transparent"],
          },
          {
            title: intl.messages["general.buttons.confirm"],
            event: () => dispatch(StoreActions.buyProduct({ idproduct: id })),
          },
        ],
        needToUpdateAddress: checkIfYouNeedToUpdateAddress(),
      }),
    );
  };

  const renderBag = (client) => {
    switch (client) {
      case "Pintou Parceria Suvinil":
        return <SuvinilBag width={268} height={268} />;
      case "hubsocial":
        return <HubsocialBag width={268} height={268} />;
      case "Conecta e Pronto":
        return <EmtelBag width={268} height={268} />;
      case "FERAs":
        return <DitoBag width={268} height={268} />;
      case "depyl action":
        return <DepylBag width={268} height={268} />;
      case "big":
        return <InterBag width={268} height={268} />;
      case "youseed":
        return <SemearBag width={268} height={268} />;
      case "ETs":
        return <PetrorioBag width={268} height={268} />;
      case "PetroRio":
        return <PetrorioBag width={268} height={268} />;
      case "ioasys":
        return <IoasysBag width={268} height={268} />;
      case "The Bakery Brasil":
        return <BakeryBag width={268} height={268} />;
      case "safers":
        return <SaferBag width={268} height={268} />;
      case "smarttbot":
        return <SmarttBotBag width={268} height={268} />;
      case "Time Cibra":
        return <CibraBag width={268} height={268} />;
      default:
        return <ImgSuccessSale width={268} height={268} />;
    }
  };
  return (
    <Portal fullHeight sidebarContext={<SidebarStore />}>
      <Flex flexDirection="column" paddingBottom>
        <PageTitle title={pageTitle || intl.messages["menu.market"]} />
        <ContainerConfetti showContainer={successPurchase}>
          <Confetti
            recycle={false}
            run={successPurchase}
            numberOfPieces={500}
          />
        </ContainerConfetti>
        {!isLoading && successPurchase ? (
          <ProductContainer>
            <ProductContent width={375}>
              <ProductName center>
                {intl.messages["store.textPage.purchaseSuccessful"]}
              </ProductName>
              <ProductDescription center>
                {default_message_to_send ||
                  intl.messages[
                    "store.textPage.allRightContactHRToRedeemYourProduct"
                  ]}
              </ProductDescription>
              <Flex mt={16} justifyContent="center">
                {renderBag(style.names.nameApp)}
              </Flex>
              <Flex mt={32} justifyContent="center">
                <Button
                  variant={["gradient"]}
                  name={intl.messages["store.backToStore"]}
                  onClick={() => history.push(urls.ROUTES.STORE, { pageTitle })}
                />
              </Flex>
            </ProductContent>
          </ProductContainer>
        ) : isLoading ? (
          <SkeletonDetailsProduct success={isLoadingSuccess} />
        ) : (
          <InfiniteScroll
            pageStart={1}
            initialLoad={false}
            useWindow={false}
            threshold={10}
          >
            {!isDesktop && (
              <GoBackButton onClick={goBack} isStorePage>
                <GoBackIcon />
              </GoBackButton>
            )}
            <ProductContainer>
              {isDesktop && (
                <GoBackButton onClick={goBack} isStorePage>
                  <GoBackIcon />
                </GoBackButton>
              )}
              <ProductContent>
                <ProductImg src={image?.url} />
                <Flex justifyContent="space-between" mt={3}>
                  <TitleContainer>
                    <Flex flexDirection="column">
                      <ProductName>{name}</ProductName>
                      <ProductKind>{kind}</ProductKind>
                    </Flex>
                    <PriceDetails spaceChildren={2}>
                      <IconCoins width={18} height={18} />
                      <ValuePrice>{valuescore}</ValuePrice>
                    </PriceDetails>
                  </TitleContainer>
                </Flex>
                <DescriptionDivider />
                <Flex flexDirection="column">
                  <pre>
                    <DescriptionContainer>
                      <ProductDescription
                        dangerouslySetInnerHTML={{
                          __html: description,
                        }}
                      />
                    </DescriptionContainer>
                  </pre>
                  <Flex justifyContent="center">
                    <Button
                      variant={
                        limit_reached || amount === 0
                          ? ["gradient", "disabled"]
                          : [
                              "gradient",
                              manageClasses({ disabled: amount === 0 }),
                            ]
                      }
                      name={
                        limit_reached
                          ? intl.messages["store.limitReached"]
                          : amount === 0
                          ? intl.messages["store.textPage.outOfStock"]
                          : intl.messages["general.buttons.buy"]
                      }
                      onClick={limit_reached ? () => {} : confirmBuy}
                      isLoading={isLoadingProductV2}
                    />
                  </Flex>
                </Flex>
              </ProductContent>
            </ProductContainer>
          </InfiniteScroll>
        )}
      </Flex>
    </Portal>
  );
};

export default DetailsProduct;
